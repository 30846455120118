import { AnalysisStatus } from '@app/models/analysis.model';
import { Color } from '@atomic/obj.constants';
import { QuoteStrings, FlowTypesLabels } from './quote.string';

const strings = QuoteStrings.pt.constants;
const flow = FlowTypesLabels.pt;

export const statusInfo = {
  accepted: { icon: 'check', color: Color.SuccessMain, badge: 'success' },
  derogate: { icon: 'exclamation-triangle', color: Color.Warning, badge: 'warning' },
  'non-analysed': { icon: 'eye-slash', color: Color.GrayDark, badge: 'neutral' },
  'non-status': { icon: 'ban', color: 'transparent', badge: 'neutral' },
  'pending-analysis': { icon: 'exclamation-circle', color: Color.GrayDark, badge: 'neutral' },
  error: { icon: 'times', color: Color.Alert, badge: 'alert' },
  PROCESSING: { icon: 'exclamation-circle', color: Color.GrayDark, badge: 'neutral' },
};

export const flowType = {
  NEW_PRODUCT: flow.NEW_PRODUCT,
  GENERAL_SPECIFICATION: flow.GENERAL_SPECIFICATION,
  SPECIFIC_SPECIFICATION: flow.SPECIFIC_SPECIFICATION,
  RECURRENT_PRODUCT: flow.RECURRENT_PRODUCT,
  CLIENT_MANUAL: flow.CLIENT_MANUAL,
};

export const quoteBadges = {
  UNDER_ANALYSIS_EPP: { label: strings.stage.label.UNDER_ANALYSIS_EPP, color: 'warning' },
  IN_VALIDATION_EPP: { label: strings.stage.label.IN_VALIDATION_EPP, color: 'warning' },
  UNDER_ANALYSIS: { label: strings.stage.label.UNDER_ANALYSIS, color: 'warning' },
  UNDER_ANALYSIS_RTC: { label: strings.stage.label.UNDER_ANALYSIS_RTC, color: 'purple' },
  DATA_VALIDATION_RTC: { label: strings.stage.label.DATA_VALIDATION_RTC, color: 'purple' },
  VALIDATION_RTC: { label: strings.stage.label.VALIDATION_RTC, color: 'purple' },
  UNDER_ANALYSIS_PED: { label: strings.stage.label.UNDER_ANALYSIS_PED, color: 'primary' },
  IN_VALIDATION_PED: { label: strings.stage.label.IN_VALIDATION_PED, color: 'primary' },
  IN_VALIDATION: { label: strings.stage.label.IN_VALIDATION, color: 'primary' },
  PENDING: { label: strings.stage.label.PENDING, color: 'brown' },
  UNDER_ANALYSIS_GQ: { label: strings.stage.label.UNDER_ANALYSIS_GQ, color: 'purple' },
  IN_VALIDATION_GQ: { label: strings.stage.label.IN_VALIDATION_GQ, color: 'purple' },
  WAITING_CLIENT_REVIEW: { label: strings.stage.label.WAITING_CLIENT_REVIEW, color: 'orange' },
  WAITING_CLIENT_REVIEW_CLIENT: { label: strings.stage.label.WAITING_CLIENT_REVIEW_CLIENT, color: 'orange' },
  CLIENT_EDITION: { label: strings.stage.label.CLIENT_EDITION, color: 'orange' },
  FINALIZED_WITH_DEROGATION: { label: strings.stage.label.FINALIZED_WITH_DEROGATION, color: 'pink' },
  CANCELED: { label: strings.stage.label.CANCELED, color: 'pink' },
  WAITING_PRICE: { label: strings.stage.label.WAITING_PRICE, color: 'purple' },
  AVAILABLE_PRICE: { label: strings.stage.label.AVAILABLE_PRICE, color: 'success' },
  FINALIZED: { label: strings.stage.label.FINALIZED, color: 'success' },
  DRAFT: { label: strings.stage.label.REJECTED, color: 'alert' },
  REJECTED: { label: strings.stage.label.REJECTED, color: 'alert' },
  INTERNAL_QUERY_SELLER: { label: strings.stage.label.INTERNAL_QUERY_SELLER, color: 'neutral' },
  INTERNAL_QUERY: { label: strings.stage.label.INTERNAL_QUERY, color: 'neutral' },
  VALIDATION_SELLER: { label: strings.stage.label.VALIDATION_SELLER, color: 'neutral' },
  NO_CUSTOMER_RESPONSE: { label: strings.stage.label.NO_CUSTOMER_RESPONSE, color: 'neutral' },
  PROCESSING: { label: strings.stage.label.PROCESSING, color: 'neutral' },
};

export const statusOptions = [
  { value: strings.status.key.derogate, name: strings.status.label.derogate },
  { value: strings.status.key.accepted, name: strings.status.label.accepted },
  //{ value: strings.status.key.noStatus, name: strings.status.label.noStatus },
  { value: strings.status.key.notAnalysed, name: strings.status.label.notAnalysed },
  //{ value: strings.status.key.pendingAnalysis, name: strings.status.label.pendingAnalysis },
];

export const tableStatusOptions = [
  { value: strings.status.key.derogate, name: strings.status.label.derogate },
  { value: strings.status.key.accepted, name: strings.status.label.accepted },
  { value: strings.status.key.notAnalysed, name: strings.status.label.notAnalysed },
];

export const internalStatus: AnalysisStatus[] = [
  { key: strings.stage.key.processing, label: strings.stage.label.PROCESSING },
  { key: strings.stage.key.pending, label: strings.stage.label.PENDING },
  { key: strings.stage.key.internalQuery, label: strings.stage.label.INTERNAL_QUERY },
  { key: strings.stage.key.underAnalysisEpp, label: strings.stage.label.UNDER_ANALYSIS_EPP },
  { key: strings.stage.key.underAnalysisPed, label: strings.stage.label.UNDER_ANALYSIS_PED },
  { key: strings.stage.key.underAnalysisRtc, label: strings.stage.label.UNDER_ANALYSIS_RTC },
  { key: strings.stage.key.underAnalysisGq, label: strings.stage.label.UNDER_ANALYSIS_GQ },
  { key: strings.stage.key.dataValidationRtc, label: strings.stage.label.DATA_VALIDATION_RTC },
  { key: strings.stage.key.inValidationEpp, label: strings.stage.label.IN_VALIDATION_EPP },
  { key: strings.stage.key.inValidationPed, label: strings.stage.label.IN_VALIDATION_PED },
  { key: strings.stage.key.inValidationGq, label: strings.stage.label.IN_VALIDATION_GQ },
  { key: strings.stage.key.waitingClientReview, label: strings.stage.label.WAITING_CLIENT_REVIEW },
  { key: strings.stage.key.internalQuerySeller, label: strings.stage.label.INTERNAL_QUERY_SELLER },
  { key: strings.stage.key.validationRTC, label: strings.stage.label.VALIDATION_RTC },
  { key: strings.stage.key.validationSeller, label: strings.stage.label.VALIDATION_SELLER },
  { key: strings.stage.key.clientEdition, label: strings.stage.label.CLIENT_EDITION },
  { key: strings.stage.key.noCustomerResponse, label: strings.stage.label.NO_CUSTOMER_RESPONSE },
];

export const externalStatus: AnalysisStatus[] = [
  { key: strings.stage.key.processing, label: strings.stage.label.PROCESSING },
  { key: strings.stage.key.pending, label: strings.stage.label.PENDING },
  { key: strings.stage.key.underAnalysis, label: strings.stage.label.UNDER_ANALYSIS },
  { key: strings.stage.key.inValidation, label: strings.stage.label.IN_VALIDATION },
  { key: strings.stage.key.waitingClientReview, label: strings.stage.label.WAITING_CLIENT_REVIEW },
];

export const commonStatus: AnalysisStatus[] = [
  { key: strings.stage.key.processing, label: strings.stage.label.PROCESSING },
  { key: strings.stage.key.canceled, label: strings.stage.label.CANCELED },
  { key: strings.stage.key.rejected, label: strings.stage.label.REJECTED },
  { key: strings.stage.key.finalized, label: strings.stage.label.FINALIZED },
  { key: strings.stage.key.finalizedWithDerogation, label: strings.stage.label.FINALIZED_WITH_DEROGATION },
  { key: strings.stage.key.waitingPrice, label: strings.stage.label.WAITING_PRICE },
  { key: strings.stage.key.priceAvailable, label: strings.stage.label.AVAILABLE_PRICE },
  { key: strings.stage.key.noCustomerResponse, label: strings.stage.label.NO_CUSTOMER_RESPONSE },
];

export const RTC_PERMISSIONS = [
  'INTERNAL_QUERY',
  'VALIDATION_RTC',
  'DATA_VALIDATION_RTC',
  'UNDER_ANALYSIS_EPP',
  'UNDER_ANALYSIS_RTC',
  'WAITING_CLIENT_REVIEW',
  'IN_VALIDATION_EPP',
];
export const EPP_PERMISSIONS = [
  'INTERNAL_QUERY',
  'DATA_VALIDATION_RTC',
  'UNDER_ANALYSIS_EPP',
  'UNDER_ANALYSIS_RTC',
  'WAITING_CLIENT_REVIEW',
  'IN_VALIDATION_EPP',
];
export const PED_PERMISSIONS = [
  'INTERNAL_QUERY',
  'DATA_VALIDATION_RTC',
  'UNDER_ANALYSIS_PED',
  'UNDER_ANALYSIS_RTC',
  'WAITING_CLIENT_REVIEW',
  'IN_VALIDATION_PED',
];
export const GQ_PERMISSIONS = [
  'INTERNAL_QUERY',
  'DATA_VALIDATION_RTC',
  'UNDER_ANALYSIS_GQ',
  'UNDER_ANALYSIS_RTC',
  'WAITING_CLIENT_REVIEW',
  'IN_VALIDATION_GQ',
];
export const SELLER_PERMISSIONS = [
  'INTERNAL_QUERY_SELLER',
  'CLIENT_EDITION',
  'VALIDATION_SELLER',
  'VALIDATION_RTC',
  'UNDER_ANALYSIS_EPP',
  'UNDER_ANALYSIS_PED',
  'WAITING_CLIENT_REVIEW',
  'IN_VALIDATION_EPP',
  'IN_VALIDATION_PED',
];

export const brands = [
  { key: 'GERDAU_CUSTOM', label: 'Gerdau Custom' },
  { key: 'GERDAU_STANDARD', label: 'Gerdau Standard' },
  { key: 'GERDAU_MAX_CLEANITY', label: 'Gerdau Max Cleanity' },
  { key: 'GERDAU_MAX_HIDUCTILITY', label: 'Gerdau Max Hiductility' },
  { key: 'GERDAU_MAX_MACHINITY', label: 'Gerdau Max Machinity' },
  { key: 'GERDAU_MAX_PREMIUM_FLAT', label: 'Gerdau Max Premium Flat' },
  { key: 'GERDAU_TECH_ALLOYED_FLAT', label: 'Gerdau Tech Alloyed Flat' },
  { key: 'GERDAU_TECH_ECOMACHINING', label: 'Gerdau Tech Ecomachining' },
  { key: 'GERDAU_TECH_INDUHEAT', label: 'Gerdau Tech Induheat' },
];

export const usersBrandPermissions = ['SELLER', 'RTC', 'EPP', 'PED'];

export const TEST_ID_CLOSE_CREATE_ORDER_MODAL = 'close-create-order-modal-button';
export const TEST_ID_ORDER_REVIEW_MODAL = 'order-review-modal-button';
export const TEST_ID_CREATE_ORDER_MODAL = 'create-order-modal-button';
export const TEST_ID_CLOSE_ORDER_ANALYSIS_MODAL = 'close-order-analysis-modal-button';

export enum ProductTypeAndShape {
  ArameRedondo = 'ARAME_REDONDO',
  BarraRedonda = 'BARRA_REDONDO',
  BarraQuadrada = 'BARRA_QUADRADO',
  BarraSextavada = 'BARRA_SEXTAVADO',
  BarraChata = 'BARRA_CHATO',
  BarraRetangular = 'BARRA_RETANGULAR',
  BlocoQuadrado = 'BLOCO_QUADRADO',
  BlocoRetangular = 'BLOCO_RETANGULAR',
  FioMaquinaRedondo = 'FIOMAQUINA_REDONDO',
  FioMaquinaSextavado = 'FIOMAQUINA_SEXTAVADO',
  Lingote = 'LINGOTE',
  TarugoQuadrado = 'TARUGO_QUADRADO',
}
