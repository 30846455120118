import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { FontSize } from '@atomic/obj.constants';
import { Modal } from '@atomic/obj.modal';
import { IMG_RELATIVE_PATH } from './lack-of-charge-modal.constants';
import { Strings, ILackOfChargeModalStrings } from './lack-of-charge-modal.string';
import {
  LackOfChargeModalBody,
  LackOfChargeModalBodyWrapper,
  LackOfChargeModalCloseWrapper,
  LackOfChargeModalFooter,
  LackOfChargeModalHeader,
  LackOfChargeModalHeaderImage,
  LackOfChargeModalTextWrapper,
  LackOfChargeModalTitleWrapper,
  LackOfChargeModalWrapper,
} from './lack-of-charge-modal.style';
import { putLackOfChargeModal } from './store/put-lack-of-charge.thunk';

export const LackOfChargeModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { hasLackOfCharge, hasLackOfChargeAnnouncement, announcementData, openedConfigModal } = useSelector(
    (state: RootState) => state.lackOfCharge,
  );
  const dispatch = useDispatch<AppThunkDispatch>();
  const strings: ILackOfChargeModalStrings = Strings[userInfo?.language];

  const handleClose = () => {
    dispatch(putLackOfChargeModal({ key: announcementData?.announcements[0]?.key, email: userInfo?.email }));
  };

  return (
    <Modal small opened={hasLackOfCharge && hasLackOfChargeAnnouncement && !openedConfigModal}>
      <LackOfChargeModalHeader>
        <LackOfChargeModalTitleWrapper>{announcementData?.announcements[0]?.title}</LackOfChargeModalTitleWrapper>
        <LackOfChargeModalCloseWrapper>
          <FlexColumn onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
            <Image.NewsModalHeaderCloseButton />
          </FlexColumn>
        </LackOfChargeModalCloseWrapper>
      </LackOfChargeModalHeader>
      <LackOfChargeModalWrapper>
        <LackOfChargeModalBody>
          <LackOfChargeModalHeaderImage>
            <img width='300px' src={'assets/icons/brand/gerdau-logo-full.png'} />
          </LackOfChargeModalHeaderImage>
          <LackOfChargeModalBodyWrapper>
            <LackOfChargeModalTextWrapper>
              <Body size={FontSize.Large}>{announcementData?.announcements[0]?.message}</Body>
            </LackOfChargeModalTextWrapper>
            <LackOfChargeModalTextWrapper>
              <img width='60px' src={IMG_RELATIVE_PATH + announcementData?.announcements[0]?.url[0]} />
            </LackOfChargeModalTextWrapper>
          </LackOfChargeModalBodyWrapper>
          <LackOfChargeModalFooter>
            <FlexRow hAlign='center'>
              <Button testid={`${TEST_ID_CLOSE_MODAL}-lack-of-charge`} onClick={() => handleClose()}>
                {strings?.announcement.confirmButton}
              </Button>
            </FlexRow>
          </LackOfChargeModalFooter>
        </LackOfChargeModalBody>
      </LackOfChargeModalWrapper>
    </Modal>
  );
};
