import styled from 'styled-components';
import { Border, Color, Spacing } from '@atomic/obj.constants';

export const CreateOrderModalHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CreateOrderModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CreateOrderModalFooterWrapper = styled.div`
  width: 106%;
  margin-left: -3%;
  margin-top: ${Spacing.Medium};
  background-color: ${Color.White};
  padding: ${Spacing.Medium};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.12);
`;

export const FirstColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 55%;
`;

export const SecondColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  margin-left: 30px;
`;

export const OrderTableStyled = styled.div`
  margin-top: ${Spacing.Medium};

  table {
    border-collapse: collapse;
    border: 2px solid ${Color.Secondary};
    th {
      background-color: ${Color.Secondary};
      padding: ${Spacing.Medium} 0px;
    }

    tr {
      border-bottom: 2px solid ${Color.Secondary};
      td {
        input {
          height: 40px;
          background-color: ${Color.GrayXLight};
          border: 2px solid ${Color.GrayLight};
          border-radius: ${Border.Radius};
          text-align: center;
        }
      }
    }
  }
`;

export const EmptyOrderTable = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  padding-top: ${Spacing.Medium};
  padding-bottom: ${Spacing.Medium};
`;

export const ReferenceField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
