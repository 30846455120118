import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AnalysisResume, ClientDetail, CreateOrderPhase, ProductDimensionOrder } from '@app/models/analysis.model';
import { ProductTypeAndShape } from '@app/modules/quote/quote.contants';
import { TD, TR } from '@atomic/mol.table';
import { getProductRowColor } from '../../utils/utils';
import { AmountField } from './partials/amount-field/amount-field';
import { DateField } from './partials/date-field/date-field';
import { DeleteField } from './partials/delete-field/delete-field';
import { DiameterField } from './partials/diameter-field/diameter-field';
import { IsaReferenceField } from './partials/isa-reference-field/isa-reference-field';
import { LengthField } from './partials/length-field/length-field';
import { MultiLengthField } from './partials/multi-length-field/multi-length-field';
import { OrderItemField } from './partials/order-item-field/order-item-field';
import { OrderNumberField } from './partials/order-number-field/order-number-field';
import { SideField } from './partials/side-field/side-field';
import { SimpleTextField } from './partials/simple-text-field/simple-text-field';
import { ThicknessField } from './partials/thickness-field/thickness-field';
import { UnityField } from './partials/unity-field/unity-field';
import { WidthField } from './partials/width-value-field/width-value-field';

interface OrderTableBodyProps {
  item: AnalysisResume;
  phase: string;
  hasMultLength: boolean;
  clientDetail?: ClientDetail;
  productTypeAndShape: string;
  productDimensionOrders: ProductDimensionOrder[];
  setProductDimensionOrders: (dimensions: ProductDimensionOrder[]) => void;
}

export const OrderTableBody: React.FC<OrderTableBodyProps> = ({
  item,
  phase,
  hasMultLength,
  clientDetail,
  productTypeAndShape,
  productDimensionOrders,
  setProductDimensionOrders,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const today = new Date();

  const getProductTableBody = (product: ProductDimensionOrder, index: number) => {
    switch (productTypeAndShape) {
      case ProductTypeAndShape.ArameRedondo || ProductTypeAndShape.FioMaquinaRedondo:
        return getWireColumns(product);
      case ProductTypeAndShape.BarraRedonda:
        return getRoundBarColumns(product, index);
      case ProductTypeAndShape.BarraQuadrada ||
        ProductTypeAndShape.BarraSextavada ||
        ProductTypeAndShape.BlocoQuadrado ||
        ProductTypeAndShape.TarugoQuadrado:
        return getSquareBarColumns(product, index);
      case ProductTypeAndShape.BarraChata || ProductTypeAndShape.BarraRetangular || ProductTypeAndShape.BlocoRetangular:
        return getRectangleBarColumns(product, index);
      case ProductTypeAndShape.FioMaquinaSextavado || ProductTypeAndShape.Lingote:
        return getIngotColumns(product);
      default:
        return getWireColumns(product);
    }
  };

  const getWireColumns = (product: ProductDimensionOrder) => {
    return (
      <TD>
        <DiameterField productDimensionOrder={product} />
      </TD>
    );
  };

  const getRoundBarColumns = (product: ProductDimensionOrder, index: number) => {
    return (
      <>
        <TD>
          <DiameterField productDimensionOrder={product} />
        </TD>
        <TD>
          <LengthField productDimensionOrder={product} language={userInfo.language} />
        </TD>
        {hasMultLength && (
          <TD>
            <MultiLengthField productDimensionOrder={product} language={userInfo.language} index={index} />
          </TD>
        )}
      </>
    );
  };

  const getSquareBarColumns = (product: ProductDimensionOrder, index: number) => {
    return (
      <>
        <TD>
          <SideField productDimensionOrder={product} />
        </TD>
        <TD>
          <LengthField productDimensionOrder={product} language={userInfo.language} />
        </TD>
        {hasMultLength && (
          <TD>
            <MultiLengthField productDimensionOrder={product} language={userInfo.language} index={index} />
          </TD>
        )}
      </>
    );
  };

  const getRectangleBarColumns = (product: ProductDimensionOrder, index: number) => {
    return (
      <>
        <TD>
          <WidthField productDimensionOrder={product} />
        </TD>
        <TD>
          <ThicknessField productDimensionOrder={product} />
        </TD>
        <TD>
          <LengthField productDimensionOrder={product} language={userInfo.language} />
        </TD>
        {hasMultLength && (
          <TD>
            <MultiLengthField productDimensionOrder={product} language={userInfo.language} index={index} />
          </TD>
        )}
      </>
    );
  };

  const getIngotColumns = (product: ProductDimensionOrder) => {
    return (
      <TD>
        <SideField productDimensionOrder={product} />
      </TD>
    );
  };

  return (
    <>
      {productDimensionOrders.map((product: ProductDimensionOrder, index: number) => {
        const key = `${product.referenceIsa}_${index}`;

        return (
          <TR key={key} color={getProductRowColor(product, today)}>
            <TD>
              {phase === CreateOrderPhase.Edit ? (
                <OrderNumberField
                  productDimensionOrder={product}
                  productDimensionOrders={productDimensionOrders}
                  setProductDimensionOrders={setProductDimensionOrders}
                />
              ) : (
                <SimpleTextField value={product.orderNumber} />
              )}
            </TD>
            <TD>
              {phase === CreateOrderPhase.Edit ? (
                <OrderItemField
                  productDimensionOrder={product}
                  productDimensionOrders={productDimensionOrders}
                  setProductDimensionOrders={setProductDimensionOrders}
                />
              ) : (
                <SimpleTextField value={product.referenceOrderItem} />
              )}
            </TD>
            <TD>
              <IsaReferenceField item={item} clientDetail={clientDetail} productDimensionOrder={product} />
            </TD>
            {getProductTableBody(product, index)}
            <TD>
              {phase === CreateOrderPhase.Edit ? (
                <AmountField
                  index={index}
                  clientDetail={clientDetail}
                  productDimensionOrder={product}
                  productDimensionOrders={productDimensionOrders}
                  setProductDimensionOrders={setProductDimensionOrders}
                />
              ) : (
                <SimpleTextField value={product.requestedQuantity} />
              )}
            </TD>
            <TD>
              {phase === CreateOrderPhase.Edit ? (
                <UnityField
                  clientDetail={clientDetail}
                  productDimensionOrder={product}
                  productDimensionOrders={productDimensionOrders}
                  setProductDimensionOrders={setProductDimensionOrders}
                />
              ) : (
                <SimpleTextField value={product.unitMeasure} />
              )}
            </TD>
            <TD>
              {phase === CreateOrderPhase.Edit ? (
                <DateField
                  productDimensionOrder={product}
                  productDimensionOrders={productDimensionOrders}
                  setProductDimensionOrders={setProductDimensionOrders}
                />
              ) : (
                <SimpleTextField value={format(new Date(product.requestDate?.replaceAll('-', '/')), 'dd/MM/yyyy')} />
              )}
            </TD>
            {phase === CreateOrderPhase.Edit && (
              <TD>
                <DeleteField
                  productDimensionOrder={product}
                  productDimensionOrders={productDimensionOrders}
                  setProductDimensionOrders={setProductDimensionOrders}
                />
              </TD>
            )}
          </TR>
        );
      })}
    </>
  );
};
