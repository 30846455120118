import React from 'react';
import { ProductDimensionOrder } from '@app/models/analysis.model';
import { OrderFormStrings } from '@app/modules/quote/quote.string';
import { Label } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { FontSize, FontWeight } from '@atomic/obj.constants';

interface LengthFieldProps {
  language: string;
  productDimensionOrder: ProductDimensionOrder;
}

export const LengthField: React.FC<LengthFieldProps> = ({ productDimensionOrder, language }) => {
  const Strings = OrderFormStrings[language];

  return (
    <FlexColumn hAlign='flex-start' fullWidth>
      <Label weight={FontWeight.Normal} fullWidth size={FontSize.Small}>
        {`${Strings.labels.selector.minLength} ${productDimensionOrder.steelMinLength}`}
      </Label>
      <Label weight={FontWeight.Normal} fullWidth size={FontSize.Small}>
        {`${Strings.labels.selector.maxLength} ${productDimensionOrder.steelMaxLength}`}
      </Label>
    </FlexColumn>
  );
};
