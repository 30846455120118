import React from 'react';
import { ProductDimensionOrder } from '@app/models/analysis.model';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Color } from '@atomic/obj.constants';

interface IDeleteFieldProps {
  productDimensionOrder: ProductDimensionOrder;
  productDimensionOrders: ProductDimensionOrder[];
  setProductDimensionOrders: (dimensions: ProductDimensionOrder[]) => void;
}

export const DeleteField: React.FC<IDeleteFieldProps> = ({
  productDimensionOrder,
  productDimensionOrders,
  setProductDimensionOrders,
}) => {
  const onClick = () => {
    const updatedProducts = productDimensionOrders.filter(product => product.tempId !== productDimensionOrder.tempId);
    setProductDimensionOrders(updatedProducts);
  };

  return <FaIcon.Trash color={Color.Alert} onClick={onClick} />;
};
