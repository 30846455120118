export const handleShowTechnicalAnalysisCreateOrderButton = (props: any, language: string) => {
  let canShow = false;

  if (
    props.analysis.price &&
    props.analysis.price[0] > 0 &&
    props.analysis.internalStatus.key === 'FINALIZED' &&
    language === 'pt'
  ) {
    canShow = true;
  }

  return canShow;
};

export const handleShowQuoteCreateOrderButton = (props: any, language: string) => {
  let canShow = false;

  if (props.item.hasPrice && props.item.status.internal.key === 'FINALIZED' && language === 'pt') {
    canShow = true;
  }

  return canShow;
};
