import { Allowed, TableGraphicHardness, ChartGraphicHardness } from '@app/data/http/quote.dto';
import { AnalysisStatus, SimilarMaterials } from './analysis.model';
import { HistoryStatus } from './history.model';

export enum IsaInternalRoles {
  EPP = 'epp',
  RTC = 'rtc',
  PED = 'ped',
  ADMIN = 'admin',
  SELLER = 'seller',
  GQ = 'gq',
  SCM = 'scm',
  SQUAD = 'squad',
}

export enum IsaExternalRoles {
  EPP = 'EPP',
  PED = 'PED',
  RTC = 'RTC',
  CLIENT = 'CLIENT',
  ADMIN = 'ADMIN',
  SELLER = 'SELLER',
  GQ = 'GQ',
  SCM = 'SCM',
  SQUAD = 'squad',
}

export enum QuoteAttributeTypes {
  Range = 'range',
  Number = 'number',
  Text = 'text',
  Options = 'options',
  Boolean = 'boolean',
  TextArray = 'textArray',
  Table = 'table',
  Date = 'date',
  Object = 'object',
  ChartTable = 'chart_table',
}

export enum AttributesDetails {
  LENGTH_MULTIPLES = 'Múltiplo',
}

export enum QuoteAttributeStatus {
  'accepted' = 'Aceito',
  'derogate' = 'Derroga',
  'non-analysed' = 'Não analisada',
  'non-status' = 'Sem status',
  'pending-analysis' = 'Pendente de análise',
  'error' = 'Erro',
}

export enum InversedAttributeStatus {
  'accepted' = 'accepted',
  'derogate' = 'derogate',
  'non-analysed' = 'non-analysed',
  'non-status' = 'non-status',
  'error' = 'error',
}

export enum PlantType {
  Charqueadas = 'charqueadas',
  Mogi = 'mogiDasCruzes',
  Pinda = 'pindamonhangaba',
  Gerdau = 'resumoGerdau',
}

export enum TableType {
  Static = 'static',
  Dynamic = 'dynamic',
}

export enum TextType {
  Text = 'text',
  Restricted = 'restricted',
}

export enum FlowType {
  NEW_PRODUCT = 'Novo Produto',
  GENERAL_SPECIFICATION = 'Revisão norma geral',
  SPECIFIC_SPECIFICATION = 'Revisão norma específica',
  RECURRENT_PRODUCT = 'Produto recorrente',
  CLIENT_MANUAL = 'Manual do Cliente / Fornecedor',
}

export interface Responsibles {
  createdBy: string;
  email: string;
  firstName: string;
  isAdmin: boolean;
  lastName: string;
  profile: string;
  type: string;
}

export interface StagesAnalysis {
  stages: Stages[];
}

export interface SimiliarsMaterialsData {
  similarMaterials: SimilarMaterials[];
}

export interface SimiliarsMaterialsModalData {
  similarMaterials: SimilarMaterials;
}

export interface DescriptiveAnalysisData {
  element: string;
  accepted: boolean;
  descriptiveAnalysis: {
    elementNotFound?: {
      accepted: boolean;
      derogate: {
        reason: string;
        suggestion: [];
      };
    };
    informative?: {
      accepted?: boolean;
      derogate?: {
        reason?: string;
        suggestion?: [];
      };
    };
    amplitude?: {
      accepted: boolean;
      derogate: {
        reason: string;
        suggestion: [];
      };
    };
    residualMin?: {
      accepted: boolean;
      derogate: {
        reason: string;
        suggestion: [];
      };
    };
    residualMax?: {
      accepted: boolean;
      derogate: {
        reason: string;
        suggestion: [];
      };
    };
    limit?: {
      accepted?: boolean;
      derogate?: {
        reason?: string;
        suggestion?: [];
      };
    };
  };
}

export interface FullAnalysisData {
  price?: number;
  priceDate?: string;
  currencyCode?: string;
  priceObservation?: string;
  branding?: string;
  client: string;
  bp?: string;
  createdBy: AnalysisCreatedBy;
  creationDate: string;
  unformattedCreationDate: string;
  formattedId: string;
  legacyId?: string;
  requiredSteel: string;
  priority: string;
  title: string;
  analysisType?: string;
  plants: Plant[];
  internalStatus?: AnalysisStatus;
  externalStatus?: AnalysisStatus;
  askPartNumber?: boolean;
  cancelRequestAllowed?: boolean;
  cancelRequestReason?: string;
  statusStages: StagesAnalysis;
  responsibles: Responsibles[];
  permissions: AnalysisPermissions;
  specification: AnalysisSpecification[];
  requiredQuery: any;
  history: HistoryStatus[];
  assets: AssetData[];
  flow: string;
  flowType: string;
  conversationId: string;
  editedAttributes?: string[];
  currentTab?: number;
  hasDerogate: boolean;
  user: AnalysisCreatedBy;
  allowBaggNotification?: boolean;
  newProductEpp?: boolean;
  flagInternationalStandard?: boolean;
}
export interface TechnicalAnalisysStatusList {
  branding?: string;
  flowType: string;
  internalStatus?: AnalysisStatus;
  externalStatus?: AnalysisStatus;
  plants: Plant[];
  history: HistoryStatus[];
  statusStages: StagesAnalysis;
  cancelRequestReason?: string;
}

export interface AnalysisSpecification {
  key: string;
  name: string;
  type: string;
  url: string;
}

export interface AnalysisPermissions {
  isEditable: boolean;
  isDuplicatable: boolean;
  extraInfos: boolean;
  assetsEditable: boolean;
}

export interface Plant {
  key: string;
  label: string;
  attributes: QuoteAttributes[];
  review?: PlantReview;
  selection?: PlantSelection;
  validation?: PlantValidation;
  permissions: PlantPermissions;
  hasResume: boolean;
  hasDerogate: boolean;
}

export interface PlantPermissions {
  attributesAddible: boolean;
  attributesReplicable: boolean;
}

export interface AttributeLabel {
  key?: string;
  label?: string;
}

export interface QuoteAttributes {
  key: string;
  label: string;
  allowed?: Allowed[];
  detail?: string;
  type: string | QuoteAttributeTypes;
  tableType?: string | TableType;
  textType?: string | TextType;
  status: string | QuoteAttributeStatus;
  attributeValue: QuoteAttributeValue;
  arrayAttributeValue: QuoteAttributeValue[];
  numberAttributeValue: number;
  booleanAttributeValue: boolean;
  dateAttributeValue: string;
  comments: CommentsFullData;
  isEditable?: boolean;
  isDeletable?: boolean;
  unit?: any;
  derogate?: QuoteAttributeDerogate;
  permissions: AttributesPermissions;
  complementaries: QuoteAttributeComplement[];
  creation?: string;
  testMethod?: string;
  loading?: boolean;
  isCritical?: boolean;
  descriptiveAnalysis?: DescriptiveAnalysisData[];
}

export interface AttributesPermissions {
  isEditable: boolean;
  isDelible: boolean;
  commentsAddible: boolean;
  commentsReplicable: boolean;
}

export interface QuoteAttributeDerogate {
  images?: any[];
  reason: string;
  suggestion: any;
  suggestionStatus: string;
  permissions: QuoteAttributeDerogatePermissions;
}

export interface QuoteAttributeDerogatePermissions {
  isEditable: boolean;
}

export interface QuoteAttributeValue {
  columns: QuoteAttributeColumn[];
  rows: [QuoteAttributeRow[]];
  key?: string;
  label?: string;
  min?: number;
  max?: number;
}

export interface ChatValue {
  label?: string;
  table?: TableGraphicHardness;
  chart?: ChartGraphicHardness;
}

export interface QuoteAttributeComplement {
  key: string;
  label: string;
  type: QuoteAttributeTypes;
  tableValue: QuoteAttributeValue;
  textValue: string;
  chartValue?: [ChatValue];
}

export interface QuoteAttributeColumn {
  key: string;
  label: string;
  type: string;
  colSpan: number;
  rowSpan: number;
  isVisible: boolean;
  isEditable: boolean;
  isDelible: boolean;
  isDerogateColumn?: boolean;
}

export interface QuoteAttributeRow {
  key: string;
  columnRef: string;
  value: any;
  colSpan: number;
  rowSpan: number;
}
export interface PlantReview {
  isRequested: boolean;
  isReviewed: boolean;
  requestDate: string;
  requestedBy: string;
  reviewDate: string;
  reviewedBy: string;
  permissions: PlantReviewPermissions;
}

export interface PlantReviewPermissions {
  isEditable: boolean;
  isRequestable: boolean;
}

export interface PlantSelection {
  isSelected: boolean;
  selectedBy: string;
  selectionDate: string;
  permissions: PlantSelectionPermissions;
}

export interface PlantValidation {
  isValidated: boolean;
  validatedBy: string;
  validationDate: string;
  permissions: PlantValidationPermissions;
}

export interface PlantSelectionPermissions {
  isEditable: boolean;
}

export interface PlantValidationPermissions {
  isEditable: boolean;
}

export interface AvailableStatus {
  stages: Stages[];
}

export interface CurrentStatus {
  currentStatus: {
    internalStatus: CreationStatus;
    externalStatus: CreationStatus;
  };
}

export enum AvailableStatusNotificationTypes {
  specific = 'specific',
  responsible = 'responsible',
  broadcast = 'broadcast',
}

interface AvailableEmails {
  EPP: string;
  RTC: string;
  PED: string;
  CLIENT: string;
  GQ: string;
  SELLER: string;
}

export interface AvailableStatusNotification {
  type: AvailableStatusNotificationTypes;
  profiles: string[];
  profilesEmail: AvailableEmails;
}

export interface StatusUserList {
  epp: string[];
  rtc: string[];
  ped: string[];
  client: string[];
  gq: string[];
  seller: string[];
}

export interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  profile: string;
  isAdmin: boolean;
}

export interface UserListAllData {
  epp?: UserData[];
  rtc?: UserData[];
  ped?: UserData[];
  client?: UserData[];
  gq?: UserData[];
  seller?: UserData[];
  scm?: UserData[];
}

export interface Stages {
  key: string;
  label: string;
  disabled: boolean;
  notification: AvailableStatusNotification;
}

export interface TechnicalAnalysisExtraInfos {
  PPAP: string;
  PRIORITY: string;
  APQP?: string;
  PE?: string | number;
  PARTNUMBER?: string | number;
}

export interface ExtraNotifications {
  APQP?: string;
  PE?: string | number;
  FMEAS?: string;
  FMEAS_EDIT?: string;
  NEWPRODUCTEPP?: string;
  NEWPRODUCTEPPJUSTIFY?: string;
}

export interface AssetData {
  isClientVisible: boolean;
  key: string;
  filename: string;
  type: string;
  provider: string;
  user: AnalysisCreatedBy;
  creationStatus: CreationStatus;
  date: string;
  url: string;
  permissions: AssetsPermissions;
}

export interface CreationStatus {
  key: string;
  label: string;
}

export interface AssetsPermissions {
  isDelible: boolean;
  isEditable: boolean;
}

export interface CommentsFullData {
  internalComments: AttributeComments[];
  externalComments: AttributeComments[];
}

export interface CommentsPermissions {
  isEditable: boolean;
  isDelible: boolean;
}

export interface AttributeComments {
  key?: string;
  origin?: string;
  createdBy?: AnalysisCreatedBy;

  creationStatus?: CreationStatus;
  statusKey?: string;
  statusLabel?: string;
  value?: string;
  permissions?: CommentsPermissions;
  createdDate?: string;
  externalComment?: boolean;
  attributeKey?: string;
  loading?: boolean;
}
export interface AnalysisCreatedBy {
  email: string;
  firstName: string;
  lastName: string;
}

export interface AnalysisData {
  id: number;
  analysisTitle: string;
  analysisType: string;
  product: {
    title: string;
  };
  formattedId: string;
  createdBy: {
    firstName: string;
    lastName: string;
    formattedName: string;
    date: Date;
  };
  client: {
    formattedName: string;
  };
  priority: string;
  standard?: {
    clientVisible: boolean;
    url: string;
  };
  plants: {
    key: string;
    label: string;
  }[];
  permissions: {
    comments: {
      created: boolean;
      updated: boolean;
      deleted: boolean;
    };
    responsibles: {
      updated: boolean;
      created: boolean;
      deleted: boolean;
    };
    plants: {
      isRequested: boolean;
      isSelected: boolean;
      isValidated: boolean;
    };
  };
}

export interface NewAnalysisData {
  id: number;
  title: string;
  type: string;
  branding?: string;
  formattedId: string;
  product: {
    title: string;
  };
  flow: {
    key: string;
    label: string;
  };
  createdBy: {
    firstName: string;
    lastName: string;
    formattedName: string;
    date: Date;
  };
  client: {
    formattedName: string;
  };
  priority: string;
  standard?: {
    clientVisible: boolean;
    url: string;
  };
  plants?: [
    {
      key: string;
      label: string;
    },
  ];
  permissions: {
    comments: {
      created: boolean;
      updated: boolean;
      deleted: boolean;
    };
    responsibles: {
      updated: boolean;
      created: boolean;
      deleted: boolean;
    };
    plants: {
      isRequested: boolean;
      isSelected: boolean;
      isValidated: boolean;
    };
  };
}
