import { startOfMonth } from 'date-fns';
import format from 'date-fns/format';
import { AnalysisResume, DimensionOption, DimensionsResume, ProductDimensionOrder } from '@app/models/analysis.model';
import { removeMaterialError } from '@app/modules/order-input/order-input-validation';
import {
  BRSD_SALES_ORG,
  BRSI_SALES_ORG,
  MaterialCell,
  MaterialCellError,
  MaterialUnity,
} from '@app/modules/order-input/order-input.constants';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { getMounthSum, isM2Date } from '@app/modules/order-input/phases/order-input-amount/partials/amount-table/utils';
import { ProductTypeAndShape } from '@app/modules/quote/quote.contants';
import { OrderFormStrings } from '@app/modules/quote/quote.string';
import { Color } from '@atomic/obj.constants';

export const capitalizeFirstLetter = (value: string): string => {
  const lowerCaseValue = value.toLocaleLowerCase();

  return (
    String(lowerCaseValue)
      .charAt(0)
      .toUpperCase() + String(lowerCaseValue).slice(1)
  );
};

export const handleCompositeCapitalizedString = (value: string): string => {
  const lowerCaseValue = value.toLocaleLowerCase();
  let splitedValue = [];
  let result = '';

  if (lowerCaseValue.indexOf('_') > -1) {
    splitedValue = lowerCaseValue.split('_');

    result = `${capitalizeFirstLetter(splitedValue[0])} ${splitedValue[1]}`;
  } else {
    result = capitalizeFirstLetter(lowerCaseValue);
  }

  return result;
};

export const handleSpecification = specifications => {
  let standard = '';
  const formatedDateArray = specifications.date ? specifications.date.split('-') : [];

  if (specifications && formatedDateArray.length > 0) {
    standard = `${specifications.name} - REV ${specifications.revision} - DATA ${formatedDateArray[2]}/${formatedDateArray[1]}/${formatedDateArray[0]}`;
  }

  return standard;
};

export const getProductTypeAndShape = (type: string, shape: string): string => {
  let result = '';

  switch (type) {
    case 'ARAME':
      if (shape === 'REDONDO') {
        result = ProductTypeAndShape.ArameRedondo;
      }
      break;
    case 'BARRA':
      if (shape === 'REDONDO') {
        result = ProductTypeAndShape.BarraRedonda;
      } else if (shape === 'QUADRADO') {
        result = ProductTypeAndShape.BarraQuadrada;
      } else if (shape === 'SEXTAVADO') {
        result = ProductTypeAndShape.BarraSextavada;
      } else if (shape === 'CHATO') {
        result = ProductTypeAndShape.BarraChata;
      } else if (shape === 'RETANGULAR') {
        result = ProductTypeAndShape.BarraRetangular;
      }
      break;
    case 'BLOCO':
      if (shape === 'QUADRADO') {
        result = ProductTypeAndShape.BlocoQuadrado;
      } else if (shape === 'RETANGULAR') {
        result = ProductTypeAndShape.BlocoRetangular;
      }
      break;
    case 'FIO_MAQUINA':
      if (shape === 'REDONDO') {
        result = ProductTypeAndShape.FioMaquinaRedondo;
      } else if (shape === 'SEXTAVADO') {
        result = ProductTypeAndShape.FioMaquinaSextavado;
      }
      break;
    case 'LINGOTE':
      result = ProductTypeAndShape.Lingote;
      break;
    case 'TARUGO':
      if (shape === 'QUADRADO') {
        result = ProductTypeAndShape.TarugoQuadrado;
      }
      break;
    default:
      break;
  }

  return result;
};

export const getProductDimensionLabel = (productTypeAndShape: string, language: string): string => {
  let productDimensionlabel = '';
  const Strings = OrderFormStrings[language];

  switch (productTypeAndShape) {
    case ProductTypeAndShape.ArameRedondo || ProductTypeAndShape.FioMaquinaRedondo:
      productDimensionlabel = Strings.labels.title.diameter;
      break;
    case ProductTypeAndShape.BarraRedonda:
      productDimensionlabel = `${Strings.labels.title.diameter} ${Strings.labels.title.junctionE} ${Strings.labels.title.length}`;
      break;
    case ProductTypeAndShape.BarraQuadrada ||
      ProductTypeAndShape.BarraSextavada ||
      ProductTypeAndShape.BlocoQuadrado ||
      ProductTypeAndShape.TarugoQuadrado:
      productDimensionlabel = `${Strings.labels.title.side} ${Strings.labels.title.junctionE} ${Strings.labels.title.length}`;
      break;
    case ProductTypeAndShape.BarraChata || ProductTypeAndShape.BarraRetangular || ProductTypeAndShape.BlocoRetangular:
      productDimensionlabel = `${Strings.labels.title.width}, ${Strings.labels.title.thickness} ${Strings.labels.title.junctionE} ${Strings.labels.title.length}`;
      break;
    case ProductTypeAndShape.FioMaquinaSextavado || ProductTypeAndShape.Lingote:
      productDimensionlabel = Strings.labels.title.side;
      break;
    default:
      productDimensionlabel = Strings.notFoundDimension;
      break;
  }

  return productDimensionlabel;
};

export const getDropDownDimensionPlaceHolder = (productTypeAndShape: string, language: string): string => {
  let productDimensionlabel = '';
  const Strings = OrderFormStrings[language];

  switch (productTypeAndShape) {
    case ProductTypeAndShape.ArameRedondo || ProductTypeAndShape.FioMaquinaRedondo:
      productDimensionlabel = `${Strings.labels.placeHolder.descriptionStart} ${Strings.labels.placeHolder.diameter} ${Strings.labels.placeHolder.descriptionEnd}`;
      break;
    case ProductTypeAndShape.BarraRedonda:
      productDimensionlabel = `${Strings.labels.placeHolder.descriptionStart} ${Strings.labels.placeHolder.diameter} ${Strings.labels.placeHolder.junctionE} ${Strings.labels.placeHolder.length} ${Strings.labels.placeHolder.descriptionEnd}`;
      break;
    case ProductTypeAndShape.BarraQuadrada ||
      ProductTypeAndShape.BarraSextavada ||
      ProductTypeAndShape.BlocoQuadrado ||
      ProductTypeAndShape.TarugoQuadrado:
      productDimensionlabel = `${Strings.labels.placeHolder.descriptionStart} ${Strings.labels.placeHolder.side} ${Strings.labels.placeHolder.junctionE} ${Strings.labels.placeHolder.length} ${Strings.labels.placeHolder.descriptionEnd}`;
      break;
    case ProductTypeAndShape.BarraChata || ProductTypeAndShape.BarraRetangular || ProductTypeAndShape.BlocoRetangular:
      productDimensionlabel = `${Strings.labels.placeHolder.descriptionStart} ${Strings.labels.placeHolder.width}, ${Strings.labels.placeHolder.thickness} ${Strings.labels.placeHolder.junctionE} ${Strings.labels.placeHolder.length} ${Strings.labels.placeHolder.descriptionEnd}`;
      break;
    case ProductTypeAndShape.FioMaquinaSextavado || ProductTypeAndShape.Lingote:
      productDimensionlabel = `${Strings.labels.placeHolder.descriptionStart} ${Strings.labels.placeHolder.side} ${Strings.labels.placeHolder.descriptionEnd}`;
      break;
    default:
      productDimensionlabel = Strings.notFoundDimension;
      break;
  }

  return productDimensionlabel;
};

export const getEmptyOrderTablePlaceHolder = (productTypeAndShape: string, language: string): string => {
  let productDimensionlabel = '';
  const Strings = OrderFormStrings[language];

  switch (productTypeAndShape) {
    case ProductTypeAndShape.ArameRedondo || ProductTypeAndShape.FioMaquinaRedondo:
      productDimensionlabel = `${Strings.labels.table.emptyTable} ${Strings.labels.title.diameter}.`;
      break;
    case ProductTypeAndShape.BarraRedonda:
      productDimensionlabel = `${Strings.labels.table.emptyTable} ${Strings.labels.title.diameter} ${Strings.labels.placeHolder.junctionE} ${Strings.labels.title.length}.`;
      break;
    case ProductTypeAndShape.BarraQuadrada ||
      ProductTypeAndShape.BarraSextavada ||
      ProductTypeAndShape.BlocoQuadrado ||
      ProductTypeAndShape.TarugoQuadrado:
      productDimensionlabel = `${Strings.labels.table.emptyTable} ${Strings.labels.title.side} ${Strings.labels.placeHolder.junctionE} ${Strings.labels.title.length}.`;
      break;
    case ProductTypeAndShape.BarraChata || ProductTypeAndShape.BarraRetangular || ProductTypeAndShape.BlocoRetangular:
      productDimensionlabel = `${Strings.labels.table.emptyTable} ${Strings.labels.title.width}, ${Strings.labels.title.thickness} ${Strings.labels.placeHolder.junctionE} ${Strings.labels.title.length}`;
      break;
    case ProductTypeAndShape.FioMaquinaSextavado || ProductTypeAndShape.Lingote:
      productDimensionlabel = `${Strings.labels.table.emptyTable} ${Strings.labels.title.side}`;
      break;
    default:
      productDimensionlabel = Strings.notFoundDimension;
      break;
  }

  return productDimensionlabel;
};

export const isSingleOption = (item: AnalysisResume) => {
  let isSingle = false;

  if (item.product && item.product.dimensions && item.product.dimensions.length < 2) {
    isSingle = true;
  }

  return isSingle;
};

const getOptionWithMultLengthString = (dimension: DimensionsResume, language: string) => {
  let result = '';
  const Strings = OrderFormStrings[language];

  if (dimension.multiploComprimento) {
    result += `- ${Strings.labels.selector.multiLength} ${dimension.multiploComprimento}mm ${Strings.labels.selector.multiLengthMin} ${dimension.multiploComprimentoMin}mm ${Strings.labels.selector.multiLengthMax} ${dimension.multiploComprimentoMax}mm`;
  }

  return result;
};

export const getDimensionOptions = (
  productTypeAndShape: string,
  item: AnalysisResume,
  language: string,
): DimensionOption[] => {
  let optionProperty = [];
  const Strings = OrderFormStrings[language];

  if (item && item.product) {
    switch (productTypeAndShape) {
      case ProductTypeAndShape.ArameRedondo || ProductTypeAndShape.FioMaquinaRedondo:
        optionProperty = item.product?.dimensions?.map(dimension => {
          return { id: dimension.dimensionId, value: `${Strings.labels.selector.diameter} ${dimension.diametro}mm` };
        });
        break;
      case ProductTypeAndShape.BarraRedonda:
        optionProperty = item.product?.dimensions?.map(dimension => {
          const concatText = `${Strings.labels.selector.diameter} ${dimension.diametro}mm - ${Strings.labels.selector.length} ${Strings.labels.selector.minLength} ${dimension.comprimentoMin}mm ${Strings.labels.selector.maxLength} ${dimension.comprimentoMax}mm `;
          return { id: dimension.dimensionId, value: concatText + getOptionWithMultLengthString(dimension, language) };
        });
        break;
      case ProductTypeAndShape.BarraQuadrada ||
        ProductTypeAndShape.BarraSextavada ||
        ProductTypeAndShape.BlocoQuadrado ||
        ProductTypeAndShape.TarugoQuadrado:
        optionProperty = item.product?.dimensions?.map(dimension => {
          const concatText = `${Strings.labels.selector.side} ${dimension.lado}mm - ${Strings.labels.selector.length} ${Strings.labels.selector.minLength} ${dimension.comprimentoMin}mm ${Strings.labels.selector.maxLength} ${dimension.comprimentoMax}mm `;
          return { id: dimension.dimensionId, value: concatText + getOptionWithMultLengthString(dimension, language) };
        });
        break;
      case ProductTypeAndShape.BarraChata || ProductTypeAndShape.BarraRetangular || ProductTypeAndShape.BlocoRetangular:
        optionProperty = item.product?.dimensions?.map(dimension => {
          const concatText = `${Strings.labels.selector.width} ${dimension.largura}mm - ${Strings.labels.selector.thickness} ${dimension.espessura}mm - ${Strings.labels.selector.length} ${Strings.labels.selector.minLength} ${dimension.comprimentoMin}mm ${Strings.labels.selector.maxLength} ${dimension.comprimentoMax}mm `;
          return { id: dimension.dimensionId, value: concatText + getOptionWithMultLengthString(dimension, language) };
        });
        break;
      case ProductTypeAndShape.FioMaquinaSextavado || ProductTypeAndShape.Lingote:
        optionProperty = item.product?.dimensions?.map(dimension => {
          return { id: dimension.dimensionId, value: `${Strings.labels.selector.side} ${dimension.lado}mm` };
        });
        break;
      default:
        optionProperty = item.product?.dimensions?.map(dimension => {
          return { id: dimension.dimensionId, value: `${Strings.labels.selector.diameter} ${dimension.diametro}mm` };
        });
        break;
    }
  }

  return optionProperty;
};

const getDimensionFromSelectedOption = (item: AnalysisResume, selectedOptionID: string): DimensionsResume => {
  const dimensionResume = item.product?.dimensions?.find(dimension => dimension.dimensionId === selectedOptionID);

  if (dimensionResume) {
    return dimensionResume;
  }

  return null;
};

const getProductDimensionOrderTempId = (currentProductDimensionOrders: ProductDimensionOrder[]) => {
  let tempId = 1;

  if (currentProductDimensionOrders && currentProductDimensionOrders.length > 0) {
    tempId = currentProductDimensionOrders.length + 1;
  }

  return tempId;
};

export const getProductDimensionOrder = (
  item: AnalysisResume,
  dimensionOption: DimensionOption,
  currentProductDimensionOrders: ProductDimensionOrder[],
): ProductDimensionOrder => {
  const dimensionResume = getDimensionFromSelectedOption(item, dimensionOption.id);

  return {
    tempId: getProductDimensionOrderTempId(currentProductDimensionOrders),
    salesDocumentType: '',
    salesOrganization: '',
    activitySector: '',
    orderNumber: '',
    referenceOrderItem: '',
    requestDate: '',
    issuer: item.client?.code,
    receiver: item.client?.code,
    plantCode: '',
    material: '',
    partNumber: '',
    requestedQuantity: '',
    unitMeasure: '',
    shippingDate: '',
    steelName: item.product?.steelName,
    steelShape: capitalizeFirstLetter(item.product?.shape),
    steelStandard: handleSpecification(item.specifications),
    steelHeatTreatment: handleCompositeCapitalizedString(item.product?.heatTreatment),
    productType: item.product?.type,
    steelProcess: capitalizeFirstLetter(item.product?.gerdauProcess),
    steelFinish: handleCompositeCapitalizedString(item.product?.surfaceFinish),
    referenceIsa: item.formattedId,
    steelDiameter: dimensionResume?.diametro ? `${dimensionResume.diametro} mm` : '',
    steelMinLength: dimensionResume?.comprimentoMin ? `${dimensionResume.comprimentoMin} mm` : '',
    steelMaxLength: dimensionResume?.comprimentoMax ? `${dimensionResume.comprimentoMax} mm` : '',
    steelMultLength: dimensionResume?.multiploComprimento ? `${dimensionResume.multiploComprimento} mm` : '-',
    steelMultLengthMin: dimensionResume?.multiploComprimentoMin ? `${dimensionResume.multiploComprimentoMin} mm` : '-',
    steelMultLengthMax: dimensionResume?.multiploComprimentoMax ? `${dimensionResume.multiploComprimentoMax} mm` : '-',
    steelSide: dimensionResume?.lado ? `${dimensionResume.lado} mm` : '',
    steelThickness: dimensionResume?.espessura ? `${dimensionResume.espessura} mm` : '',
    steelWidth: dimensionResume?.largura ? `${dimensionResume.largura} mm` : '',
  };
};

const getProductInvalidMinimunAmount = (
  productDimensionOrder: ProductDimensionOrder,
  salesOrg: string,
  language: string,
) => {
  let returnedValue: any = null;
  const string = OrderInputStrings[language].dateAndAmount.validation;

  if (salesOrg === BRSD_SALES_ORG || salesOrg === BRSI_SALES_ORG) {
    if (
      productDimensionOrder.unitMeasure === MaterialUnity.Kg &&
      parseFloat(productDimensionOrder.requestedQuantity) < 10000
    ) {
      returnedValue = {
        code: '11',
        message: string.minKG,
        details: string.minKGDetail,
      };
    }

    if (
      productDimensionOrder.unitMeasure === MaterialUnity.Ton &&
      parseFloat(productDimensionOrder.requestedQuantity) < 10
    ) {
      returnedValue = {
        code: '12',
        message: string.minTon,
        details: string.minTonDetail,
      };
    }
  }

  return returnedValue;
};

const validateProductAmount = (productDimensionOrder: ProductDimensionOrder, salesOrg: string, language: string) => {
  let isValid = true;

  const invalidMinnimunAmount = getProductInvalidMinimunAmount(productDimensionOrder, salesOrg, language);

  if (
    (productDimensionOrder.unitMeasure === MaterialUnity.Kg &&
      parseFloat(productDimensionOrder.requestedQuantity) < 1000) ||
    (productDimensionOrder.unitMeasure === MaterialUnity.Kg &&
      parseFloat(productDimensionOrder.requestedQuantity) > 999999) ||
    (productDimensionOrder.unitMeasure === MaterialUnity.Ton &&
      parseFloat(productDimensionOrder.requestedQuantity) < 1) ||
    (productDimensionOrder.unitMeasure === MaterialUnity.Ton &&
      parseFloat(productDimensionOrder.requestedQuantity) > 999) ||
    invalidMinnimunAmount
  ) {
    isValid = false;
  }

  return isValid;
};

export const handleProductAmountAndUnityChange = (
  productDimensionOrder: ProductDimensionOrder,
  salesOrg: string,
  language: string,
): ProductDimensionOrder => {
  if (validateProductAmount(productDimensionOrder, salesOrg, language)) {
    productDimensionOrder.messages = removeMaterialError(productDimensionOrder.messages, MaterialCell.Amount);
    productDimensionOrder.messages = removeMaterialError(productDimensionOrder.messages, MaterialCell.Unity);
  } else {
    const materialMessages = productDimensionOrder.messages || [];
    const messages = [...materialMessages, getProductAmountError(productDimensionOrder, salesOrg, language)];
    productDimensionOrder.messages = messages;
  }

  return productDimensionOrder;
};

export const getProductRowColor = (productDimensionOrder: ProductDimensionOrder, today: Date) => {
  const firstDayOfMonth = startOfMonth(today);
  const m2Month = getMounthSum(today);

  if (productDimensionOrder.requestDate) {
    if (isM2Date(firstDayOfMonth, new Date(productDimensionOrder.requestDate), m2Month)) {
      return Color.Yellow;
    }
  }

  if (productDimensionOrder?.messages) {
    if (productDimensionOrder.messages.filter(m => m.code !== MaterialCellError.DateOutOfRange)?.length) {
      return Color.AlertLight;
    }
  }

  return null;
};

export const hasProductInputError = (productDimensionOrder: ProductDimensionOrder, materialCell: MaterialCell) => {
  let message = '';

  if (productDimensionOrder.messages) {
    // eslint-disable-next-line complexity
    productDimensionOrder.messages.forEach(m => {
      switch (materialCell) {
        case MaterialCell.Amount:
          if (
            m.code === MaterialCellError.TonQtyOutOfRange ||
            m.code === MaterialCellError.KgQtyOutOfRange ||
            m.code === MaterialCellError.MinKGAmount ||
            m.code === MaterialCellError.MinTonAmount ||
            (!productDimensionOrder.requestedQuantity && m.code === MaterialCellError.RequiredField)
          ) {
            message = m.message;
          }
          break;
        case MaterialCell.Unity:
          if (
            m.code === MaterialCellError.InvalidUnity ||
            (!productDimensionOrder.unitMeasure && m.code === MaterialCellError.RequiredField)
          ) {
            message = m.message;
          }
          break;
        case MaterialCell.Date:
          if (
            m.code === MaterialCellError.PastDate ||
            m.code === MaterialCellError.BusinessDay ||
            m.code === MaterialCellError.InvalidDateFormat ||
            m.code === MaterialCellError.InvalidDate ||
            (!productDimensionOrder.requestDate && m.code === MaterialCellError.RequiredField)
          ) {
            message = m.message;
          }
          break;
        case MaterialCell.OrderNumber:
          if (!productDimensionOrder.referenceOrderItem && m.code === MaterialCellError.RequiredField) {
            message = m.message;
          }
          break;
        default:
          break;
      }
    });
  }

  return message;
};

export const hasProductError = (productDimensionOrder: ProductDimensionOrder, materialCell: MaterialCell): boolean => {
  let error = false;

  if (!productDimensionOrder) {
    return error;
  }

  if (productDimensionOrder.messages) {
    // eslint-disable-next-line complexity
    productDimensionOrder.messages.forEach(m => {
      switch (materialCell) {
        case MaterialCell.Amount:
          if (
            m.code === MaterialCellError.TonQtyOutOfRange ||
            m.code === MaterialCellError.KgQtyOutOfRange ||
            m.code === MaterialCellError.MinKGAmount ||
            m.code === MaterialCellError.MinTonAmount ||
            (!productDimensionOrder.requestedQuantity && m.code === MaterialCellError.RequiredField)
          ) {
            error = true;
          }
          break;
        case MaterialCell.Unity:
          if (
            m.code === MaterialCellError.InvalidUnity ||
            (!productDimensionOrder.unitMeasure && m.code === MaterialCellError.RequiredField)
          ) {
            error = true;
          }
          break;
        case MaterialCell.Date:
          if (
            m.code === MaterialCellError.PastDate ||
            m.code === MaterialCellError.BusinessDay ||
            m.code === MaterialCellError.InvalidDateFormat ||
            m.code === MaterialCellError.InvalidDate ||
            (!productDimensionOrder.requestDate && m.code === MaterialCellError.RequiredField)
          ) {
            error = true;
          }
          break;
        case MaterialCell.OrderNumber:
          if (!productDimensionOrder.orderNumber && m.code === MaterialCellError.RequiredField) {
            error = true;
          }
          break;
        default:
          break;
      }
    });
  }

  return error;
};

export const getProductAmountError = (
  productDimensionOrder: ProductDimensionOrder,
  salesOrg: string,
  language: string,
) => {
  let returnedValue: any = null;
  const string = OrderInputStrings[language].dateAndAmount.validation;

  if (
    (productDimensionOrder.unitMeasure === MaterialUnity.Kg &&
      parseFloat(productDimensionOrder.requestedQuantity) < 1000) ||
    (productDimensionOrder.unitMeasure === MaterialUnity.Kg &&
      parseFloat(productDimensionOrder.requestedQuantity) > 999999)
  ) {
    returnedValue = {
      code: '4',
      message: string.KGinterval,
      details: string.KGdetails,
    };
  }

  if (
    (productDimensionOrder.unitMeasure === MaterialUnity.Ton &&
      parseFloat(productDimensionOrder.requestedQuantity) < 1) ||
    (productDimensionOrder.unitMeasure === MaterialUnity.Ton &&
      parseFloat(productDimensionOrder.requestedQuantity) > 999)
  ) {
    returnedValue = {
      code: '3',
      message: string.Tinterval,
      details: string.Tdetails,
    };
  }

  if (salesOrg === BRSD_SALES_ORG || salesOrg === BRSI_SALES_ORG) {
    if (
      productDimensionOrder.unitMeasure === MaterialUnity.Kg &&
      parseFloat(productDimensionOrder.requestedQuantity) < 10000
    ) {
      returnedValue = {
        code: '11',
        message: string.minKG,
        details: string.minKGDetail,
      };
    }

    if (
      productDimensionOrder.unitMeasure === MaterialUnity.Ton &&
      parseFloat(productDimensionOrder.requestedQuantity) < 10
    ) {
      returnedValue = {
        code: '12',
        message: string.minTon,
        details: string.minTonDetail,
      };
    }
  }

  return returnedValue;
};

export const isFirstM2ProductCaseSelector = (
  productDimensionOrders: ProductDimensionOrder[],
  tempId: number,
): boolean => {
  const today = new Date();
  const firstDayOfMonth = startOfMonth(today);
  const m2Month = getMounthSum(today);

  const firstM2Material: ProductDimensionOrder = productDimensionOrders.find(
    (productDimensionOrder: ProductDimensionOrder) => {
      if (productDimensionOrder.requestDate) {
        const m2 = isM2Date(firstDayOfMonth, new Date(productDimensionOrder.requestDate), m2Month);
        return m2;
      }
      return false;
    },
  );

  if (firstM2Material) {
    return firstM2Material.tempId === tempId;
  }
  return false;
};

export const mapProductDimensionOrderToRequest = (productDimensionOrders: ProductDimensionOrder[]) => {
  const mappedOrders = productDimensionOrders.map(productDimensionOrder => {
    return {
      salesDocumentType: productDimensionOrder.salesDocumentType,
      salesOrganization: productDimensionOrder.salesOrganization,
      activitySector: productDimensionOrder.activitySector,
      orderNumber: productDimensionOrder.orderNumber,
      referenceOrderItem: productDimensionOrder.referenceOrderItem,
      requestDate: format(new Date(), 'yyyy-MM-dd'),
      issuer: productDimensionOrder.issuer,
      receiver: productDimensionOrder.receiver,
      plantCode: productDimensionOrder.plantCode,
      material: productDimensionOrder.material,
      partNumber: productDimensionOrder.partNumber,
      requestedQuantity: productDimensionOrder.requestedQuantity,
      unitMeasure: productDimensionOrder.unitMeasure,
      shippingDate: productDimensionOrder.requestDate,
      steelName: productDimensionOrder.steelName,
      steelShape: productDimensionOrder.steelShape,
      steelStandard: productDimensionOrder.steelStandard,
      steelHeatTreatment: productDimensionOrder.steelHeatTreatment,
      productType: productDimensionOrder.productType,
      steelProcess: productDimensionOrder.steelProcess,
      steelFinish: productDimensionOrder.steelFinish,
      referenceIsa: productDimensionOrder.referenceIsa,
      steelDiameter: productDimensionOrder.steelDiameter,
      steelMinLength: productDimensionOrder.steelMinLength,
      steelMaxLength: productDimensionOrder.steelMaxLength,
      steelMultLength: productDimensionOrder.steelMultLength,
      steelMultLengthMin: productDimensionOrder.steelMultLengthMin,
      steelMultLengthMax: productDimensionOrder.steelMultLengthMax,
      steelSide: productDimensionOrder.steelSide,
      steelThickness: productDimensionOrder.steelThickness,
      steelWidth: productDimensionOrder.steelWidth,
      multipleValidation: productDimensionOrder.multipleValidation,
    };
  });

  return mappedOrders;
};
