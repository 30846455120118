import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { ClientDetail, ProductDimensionOrder } from '@app/models/analysis.model';
import {
  AMOUNT_TABLE_AMOUNT_TOOLTIP_ARROW_LEFT_POSITION_REF,
  AMOUNT_TABLE_AMOUNT_TOOLTIP_LEFT_POSITION_REF,
  AMOUNT_TABLE_AMOUNT_TOOLTIP_TOP_POSITION_REF,
  AMOUNT_TABLE_AMOUNT_TOOLTIP_WIDTH,
  MaterialCell,
} from '@app/modules/order-input/order-input.constants';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { FlexColumn } from '@atomic/obj.box';
import { FontSize, Spacing } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { handleProductAmountAndUnityChange, hasProductError, hasProductInputError } from '../../../../utils/utils';
import { AmountErrorWrapper, AmountInput } from './amount-field.style';

interface IAmountFieldProps {
  index: number;
  clientDetail?: ClientDetail;
  productDimensionOrder: ProductDimensionOrder;
  productDimensionOrders: ProductDimensionOrder[];
  setProductDimensionOrders: (dimensions: ProductDimensionOrder[]) => void;
}

const strings = OrderInputStrings.pt.dateAndAmount;

export const AmountField: React.FC<IAmountFieldProps> = ({
  index,
  clientDetail,
  productDimensionOrder,
  productDimensionOrders,
  setProductDimensionOrders,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [show, setShow] = useState(true);
  const ref = useRef(null);

  const handleOnInput = e => {
    const val = e.target.value.replace(/[^0-9.]/g, '').replace(/(..*)./g, '$1');
    return val;
  };

  let topTooltip = 0;
  let leftTooltip = 0;

  if (ref?.current) {
    const iconBoundings = ref?.current?.getBoundingClientRect();
    topTooltip = iconBoundings.top;
    leftTooltip = iconBoundings.left;
  }

  const customTop = topTooltip + AMOUNT_TABLE_AMOUNT_TOOLTIP_TOP_POSITION_REF;
  const customLeft = leftTooltip - AMOUNT_TABLE_AMOUNT_TOOLTIP_LEFT_POSITION_REF;
  const isFirstField = index === 0;

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current) {
      const isOutsideClick = ref && !ref.current.contains(event.target);

      if (isOutsideClick) {
        setShow(false);
      }
    }
  };

  const handleValueChange = (value: string) => {
    const updatedProducts = productDimensionOrders.map(product => {
      if (product.tempId === productDimensionOrder.tempId) {
        product.requestedQuantity = value;
      }

      product = handleProductAmountAndUnityChange(product, clientDetail?.salesOrganization, userInfo.language);
      return product;
    });

    setProductDimensionOrders(updatedProducts);
  };

  useEventListener('click', handleClickOutside);

  return (
    <FlexColumn>
      <FlexColumn ref={ref} pt={Spacing.Medium}>
        <AmountInput
          className='numberInputBox'
          hasError={hasProductError(productDimensionOrder, MaterialCell.Amount)}
          onInput={e => handleOnInput(e)}
          defaultValue={productDimensionOrder.requestedQuantity}
          onBlur={e => {
            handleValueChange(e.target.value);
          }}
        />
        <CustomizedTooltip
          description={strings.amountMessageTooltip}
          visible={show && isFirstField}
          onClick={() => setShow(false)}
          onClose={() => setShow(false)}
          customHeight={customTop}
          customLeftAlign={customLeft}
          customArrowX={AMOUNT_TABLE_AMOUNT_TOOLTIP_ARROW_LEFT_POSITION_REF}
          hasFooter={false}
          hasHeader={false}
          customWidth={AMOUNT_TABLE_AMOUNT_TOOLTIP_WIDTH}
          fontSize={FontSize.XSmall}
          hasOffset
        />
      </FlexColumn>
      <AmountErrorWrapper>{hasProductInputError(productDimensionOrder, MaterialCell.Amount)}</AmountErrorWrapper>
    </FlexColumn>
  );
};
