import React from 'react';
import { Label } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { FontSize, FontWeight } from '@atomic/obj.constants';

interface SimpleTextFieldProps {
  value: string;
}

export const SimpleTextField: React.FC<SimpleTextFieldProps> = ({ value }) => {
  return (
    <FlexColumn hAlign='center' fullWidth>
      <Label weight={FontWeight.Normal} fullWidth size={FontSize.Small}>
        {value}
      </Label>
    </FlexColumn>
  );
};
