import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AnalysisResume, CreateOrderPhase, DimensionOption, ProductDimensionOrder } from '@app/models/analysis.model';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { getDropDownDimensionPlaceHolder, getProductDimensionLabel, getProductDimensionOrder } from '../utils/utils';
import { DimensionOptions } from './dimension-options.component';
import { DimensionsSelectorWrapper, DimensionWrapper } from './dimension-selector.style';

interface DimensionSelectorProps {
  item?: AnalysisResume;
  phase: string;
  productTypeAndShape: string;
  dimensionOptions: DimensionOption[];
  productDimensionOrders: ProductDimensionOrder[];
  setProductDimensionOrder: (dimensions: ProductDimensionOrder[]) => void;
}

export const DimensionSelector: React.FC<DimensionSelectorProps> = ({
  item,
  phase,
  productTypeAndShape,
  dimensionOptions,
  productDimensionOrders,
  setProductDimensionOrder,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [openDimensionsOptions, setOpenDimensionsOptions] = React.useState(false);

  const handleOnAddDimension = (dimension: DimensionOption) => {
    const productDimensionOrder = getProductDimensionOrder(item, dimension, productDimensionOrders);
    setProductDimensionOrder([...productDimensionOrders, productDimensionOrder]);
    setOpenDimensionsOptions(false);
  };

  return (
    <FlexColumn>
      <Body bold={700}>{getProductDimensionLabel(productTypeAndShape, userInfo.language)}</Body>
      <DimensionsSelectorWrapper>
        <FlexRow fullWidth>
          {phase === CreateOrderPhase.Edit ? (
            <DimensionWrapper onClick={() => setOpenDimensionsOptions(!openDimensionsOptions)}>
              <Body>{getDropDownDimensionPlaceHolder(productTypeAndShape, userInfo.language)}</Body>
              <FaIcon.ChevronDown size='1x' />
            </DimensionWrapper>
          ) : (
            <DimensionWrapper>
              <Body>{getDropDownDimensionPlaceHolder(productTypeAndShape, userInfo.language)}</Body>
              <FaIcon.ChevronDown size='1x' color={Color.Gray} />
            </DimensionWrapper>
          )}
        </FlexRow>
        <DimensionOptions
          open={openDimensionsOptions}
          dimensionOptions={dimensionOptions}
          onClick={handleOnAddDimension}
        />
      </DimensionsSelectorWrapper>
    </FlexColumn>
  );
};
