import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { DimensionOption } from '@app/models/analysis.model';
import { Button } from '@atomic/atm.button';
import { Label } from '@atomic/atm.typography';
import { OrderFormStrings } from '../../../quote.string';
import { DimensionOptionsWrapper, DimensionOptionWrapper } from './dimension-selector.style';

interface DimensionOptionsProps {
  open: boolean;
  dimensionOptions: DimensionOption[];
  onClick: (option) => void;
}

export const DimensionOptions: React.FC<DimensionOptionsProps> = ({ open, dimensionOptions, onClick }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const Strings = OrderFormStrings[userInfo.language];

  if (open) {
    return (
      <DimensionOptionsWrapper>
        {dimensionOptions.map((dimension: DimensionOption) => (
          <DimensionOptionWrapper onClick={() => onClick(dimension)} key={dimension.id} value={dimension.value}>
            <Label>{dimension.value}</Label>
            <Button kind='link'>{Strings.addButton}</Button>
          </DimensionOptionWrapper>
        ))}
      </DimensionOptionsWrapper>
    );
  }

  return null;
};
