import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { CreateOrderPhase, ProductDimensionOrder } from '@app/models/analysis.model';
import {
  TEST_ID_CLOSE_CREATE_ORDER_MODAL,
  TEST_ID_CREATE_ORDER_MODAL,
  TEST_ID_ORDER_REVIEW_MODAL,
} from '@app/modules/quote/quote.contants';
import { OrderFormStrings } from '@app/modules/quote/quote.string';
import { Button } from '@atomic/atm.button';
import { FlexRow } from '@atomic/obj.box';
import { CreateOrderModalFooterWrapper } from '../create-order-modal.style';

interface CreateOrderModalFooterProps {
  isLoading: boolean;
  phase: string;
  productDimensionOrders: ProductDimensionOrder[];
  setPhase: (phaseName: string) => void;
  onClose: () => void;
  onCreate: () => void;
}

export const CreateOrderModalFooter: React.FC<CreateOrderModalFooterProps> = ({
  isLoading,
  phase,
  productDimensionOrders,
  setPhase,
  onClose,
  onCreate,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const Strings = OrderFormStrings[userInfo.language];

  const handleEnable = () => {
    let isEnable = false;

    if (productDimensionOrders) {
      productDimensionOrders.forEach(product => {
        if (
          product.requestedQuantity &&
          product.unitMeasure &&
          product.requestDate &&
          product.messages &&
          product.messages.length === 0
        ) {
          isEnable = true;
        }
      });
    }

    return isEnable;
  };

  return (
    <CreateOrderModalFooterWrapper>
      <FlexRow>
        <Button
          kind='secondary'
          loading={isLoading}
          testid={`${TEST_ID_CLOSE_CREATE_ORDER_MODAL}`}
          onClick={() => onClose()}
        >
          {Strings.cancellButton}
        </Button>
      </FlexRow>
      <FlexRow hAlign='center' ml>
        {phase === CreateOrderPhase.Edit ? (
          <Button
            disabled={!handleEnable()}
            loading={isLoading}
            testid={`${TEST_ID_ORDER_REVIEW_MODAL}`}
            onClick={() => setPhase(CreateOrderPhase.Review)}
          >
            {Strings.reviewButton}
          </Button>
        ) : (
          <Button
            disabled={!handleEnable()}
            loading={isLoading}
            testid={`${TEST_ID_CREATE_ORDER_MODAL}`}
            onClick={() => onCreate()}
          >
            {Strings.confirmButton}
          </Button>
        )}
      </FlexRow>
    </CreateOrderModalFooterWrapper>
  );
};
