import styled from 'styled-components';
import { FlexRow } from '@atomic/obj.box';
import { Color, FontFamily, Spacing } from '@atomic/obj.constants';

export const DimensionsSelectorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: ${Spacing.Small};
`;

export const DimensionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  min-width: 400px;
  height: 50px;
  background-color: ${Color.GrayXLight};
  border: 1px solid ${Color.GrayLightSecondary};
  border-radius: ${Spacing.XSmall};
  padding: ${Spacing.Small} ${Spacing.Small} ${Spacing.Small} ${Spacing.Small};
  cursor: pointer;
`;

export const DimensionOptionsWrapper = styled.div`
  position: absolute;
  z-index: 11;
  width: 60%;
  overflow-x: hidden;
  top: 60px;
  height: auto;
  max-height: calc(100vh - 640px);
  min-height: 70px;
  overflow-y: auto;
  background-color: ${Color.White};
  border: '1px solid ${Color.GrayLight}';
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.XXSmall};
  font-family: ${FontFamily.Primary};
`;

export const DimensionOptionWrapper = styled(FlexRow)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-left: ${Spacing.Small};

  & > label {
    cursor: pointer;
  }

  &:hover {
    background-color: ${Color.GrayLight};
  }
`;
