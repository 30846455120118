import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AnalysisResume, DimensionOption, ProductDimensionOrder } from '@app/models/analysis.model';
import { OrderFormStrings } from '@app/modules/quote/quote.string';
import { Body, H1 } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { CreateOrderModalHeaderWrapper, FirstColumnWrapper, SecondColumnWrapper } from '../create-order-modal.style';
import { DimensionSelector } from '../dimension-selector/dimension-selector.component';
import {
  capitalizeFirstLetter,
  getProductTypeAndShape,
  handleCompositeCapitalizedString,
  handleSpecification,
} from '../utils/utils';

interface CreateOrderModalHeaderProps {
  title: string;
  phase: string;
  item: AnalysisResume;
  dimensionOptions: DimensionOption[];
  productDimensionOrders: ProductDimensionOrder[];
  setProductDimensionOrders: (dimensions: ProductDimensionOrder[]) => void;
}

export const CreateOrderModalHeader: React.FC<CreateOrderModalHeaderProps> = ({
  title,
  phase,
  item,
  dimensionOptions,
  productDimensionOrders,
  setProductDimensionOrders,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const Strings = OrderFormStrings[userInfo.language];
  const productTypeAndShape = getProductTypeAndShape(item?.product?.type, item?.product?.shape);

  if (item && item.product) {
    return (
      <CreateOrderModalHeaderWrapper>
        <FlexColumn>
          <VSeparator />
          <H1>{title}</H1>
          <VSeparator />
          <FlexRow>
            <FirstColumnWrapper>
              <FlexColumn>
                <FlexRow>
                  <Body bold={700}>{Strings.steel}</Body>
                  <Body ml={Spacing.Small}>{item.product.steelName}</Body>
                </FlexRow>
                <FlexRow mt={Spacing.Medium}>
                  <Body bold={700}>{Strings.form}</Body>
                  <Body ml={Spacing.Small}>{capitalizeFirstLetter(item.product.shape)}</Body>
                </FlexRow>
              </FlexColumn>
              <FlexColumn>
                <FlexRow>
                  <Body bold={700}>{Strings.technicalStandard}</Body>
                  <Body ml={Spacing.Small}>{handleSpecification(item.specifications)}</Body>
                </FlexRow>
                <FlexRow mt={Spacing.Medium}>
                  <Body bold={700}>{Strings.heatTreatment}</Body>
                  <Body ml={Spacing.Small}>{handleCompositeCapitalizedString(item.product.heatTreatment)}</Body>
                </FlexRow>
              </FlexColumn>
            </FirstColumnWrapper>
            <SecondColumnWrapper>
              <FlexColumn>
                <FlexRow>
                  <Body bold={700}>{Strings.productType}</Body>
                  <Body ml={Spacing.Small}>{capitalizeFirstLetter(item.product.type)}</Body>
                </FlexRow>
                <FlexRow mt={Spacing.Medium}>
                  <Body bold={700}>{Strings.finishingDetail}</Body>
                  <Body ml={Spacing.Small}>{handleCompositeCapitalizedString(item.product.surfaceFinish)}</Body>
                </FlexRow>
              </FlexColumn>
              <FlexColumn>
                <FlexRow>
                  <Body bold={700}>{Strings.process}</Body>
                  <Body ml={Spacing.Small}>{capitalizeFirstLetter(item.product.gerdauProcess)}</Body>
                </FlexRow>
              </FlexColumn>
            </SecondColumnWrapper>
          </FlexRow>
          <VSeparator />
        </FlexColumn>
        <FlexRow>
          <DimensionSelector
            item={item}
            phase={phase}
            productTypeAndShape={productTypeAndShape}
            dimensionOptions={dimensionOptions}
            productDimensionOrders={productDimensionOrders}
            setProductDimensionOrder={setProductDimensionOrders}
          />
        </FlexRow>
      </CreateOrderModalHeaderWrapper>
    );
  }

  return null;
};
