import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { AnalysisResume, ClientDetail, ProductDimensionOrder } from '@app/models/analysis.model';
import { Body } from '@atomic/atm.typography';
import { Table } from '@atomic/mol.table';
import { CreateOrderModalBodyWrapper, EmptyOrderTable, OrderTableStyled } from '../create-order-modal.style';
import { getEmptyOrderTablePlaceHolder, getProductTypeAndShape } from '../utils/utils';
import { OrderTableBody } from './order-table/order-table-body.component';
import { OrderTableHeader } from './order-table/order-table-header.component';

interface CreateOrderModalBodyProps {
  item: AnalysisResume;
  phase: string;
  clientDetail?: ClientDetail;
  productDimensionOrders: ProductDimensionOrder[];
  setProductDimensionOrders: (dimensions: ProductDimensionOrder[]) => void;
}

export const CreateOrderModalBody: React.FC<CreateOrderModalBodyProps> = ({
  item,
  phase,
  clientDetail,
  productDimensionOrders,
  setProductDimensionOrders,
}) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const productTypeAndShape = getProductTypeAndShape(item?.product?.type, item?.product?.shape);
  const emptyTableString = getEmptyOrderTablePlaceHolder(productTypeAndShape, userInfo.language);
  const hasMultLength = item.product?.dimensions?.find(dimension => dimension.multiploComprimento);

  if (productDimensionOrders.length) {
    return (
      <CreateOrderModalBodyWrapper>
        <OrderTableStyled>
          <Table>
            <OrderTableHeader
              phase={phase}
              language={userInfo.language}
              productTypeAndShape={productTypeAndShape}
              hasMultLength={!!hasMultLength}
            />
            <OrderTableBody
              item={item}
              phase={phase}
              hasMultLength={!!hasMultLength}
              clientDetail={clientDetail}
              productTypeAndShape={productTypeAndShape}
              productDimensionOrders={productDimensionOrders}
              setProductDimensionOrders={setProductDimensionOrders}
            />
          </Table>
        </OrderTableStyled>
      </CreateOrderModalBodyWrapper>
    );
  }

  return (
    <CreateOrderModalBodyWrapper>
      <OrderTableStyled>
        <Table>
          <OrderTableHeader
            phase={phase}
            language={userInfo.language}
            productTypeAndShape={productTypeAndShape}
            hasMultLength={!!hasMultLength}
          />
        </Table>
      </OrderTableStyled>
      <EmptyOrderTable>
        <Body bold={700}>{emptyTableString}</Body>
      </EmptyOrderTable>
    </CreateOrderModalBodyWrapper>
  );
};
