/* eslint-disable prettier/prettier */
import styled, { keyframes, css } from 'styled-components';
import { Border, Breakpoint, Color, FontFamily, FontSize, Shadow, Spacing, ZIndex } from '@atomic/obj.constants';
import { ModalProps } from './modal.component';

export const ModalStyled = styled.div`
  position: absolute;
  visibility: ${(props: ModalProps) => (props.opened ? 'visible' : 'hidden')};
`;

const slideDown = keyframes`
  0% {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ModalOpacityStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${ZIndex.ModalOpacityStyled};
  background-color: ${Color.Black};
  opacity: ${(props: ModalProps) => (props.opened ? '0.5' : '0')};
  transition: all 0.2s ease-out;
  visibility: ${(props: ModalProps) => (props.opened ? 'visible' : 'hidden')};
`;

interface ModalBoxStyledProps {
  small?: boolean;
  medium?: boolean;
  overflow?: 'hidden' | 'auto' | 'visible';
  customWidth?: string;
  maxWidth?: string;
  customHeight?: string;
  shadow?: boolean;
  overflowX?: 'hidden' | 'auto' | 'visible';
}

const animationCSS = css`
  animation: ${slideDown} 0.3s ease-out;`;

export const ModalBoxStyled = styled.div`
  position: fixed;
  top: 30px;
  left: 50%;
  z-index: ${ZIndex.ModalBoxStyled};
  transform: translate(-50%, 0);
  width: 90%;
  max-height: 95%;
  overflow: ${(props: ModalBoxStyledProps) => (props.overflow ? props.overflow : 'auto')};
  overflow-x: ${(props: ModalBoxStyledProps) => (props.overflowX ? props.overflowX: 'auto')};
  overscroll-behavior: contain;
  background-color: ${Color.White};
  border-radius: ${Border.Radius};
  ${(props: ModalBoxStyledProps) => props.shadow && animationCSS};
  ${(props: ModalBoxStyledProps) => props.shadow && `box-shadow: ${Shadow.Small};`};


  @media all and (min-width: ${Breakpoint.sm}em) {
    width: ${(props: ModalBoxStyledProps) =>
    props.customWidth ? props.customWidth : props.small ? '40%' : props.medium ? '65%' : '90%'};

    max-width: ${(props: ModalBoxStyledProps) =>
    props.maxWidth ? props.maxWidth : 'unset'};

    ${(props: ModalBoxStyledProps) =>
      props.customHeight && 'top: 20%;'
  }
`;

export const ModalCloseStyled = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${ZIndex.ModalCloseStyled};
  padding: ${Spacing.Large};
  color: ${Color.GrayDark};
  cursor: pointer;
`;

export const DragAreaWrapper = styled.header`
  position: relative;
  padding: ${Spacing.Medium} ${Spacing.XXLarge} 0 ${Spacing.Large };
  & > div {
    cursor: move;
    border-radius: ${Border.Radius};
    padding: ${Spacing.Medium};
    background-color: ${Color.GrayLight};
    color: ${Color.GrayDark};
    font-size: ${FontSize.Small};
    font-family: ${FontFamily.Primary};
  }
`;
