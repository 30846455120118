import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Body, H1 } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { TEST_ID_CLOSE_ORDER_ANALYSIS_MODAL } from '../../quote.contants';
import { OrderFormStrings } from '../../quote.string';

interface OrderAnalysisModalProps {
  openOrderAnalysis: boolean;
  setOpenOrderAnalysis: (open: boolean) => void;
}

export const OrderAnalysisModal: React.FC<OrderAnalysisModalProps> = ({ openOrderAnalysis, setOpenOrderAnalysis }) => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const Strings = OrderFormStrings[userInfo.language];

  return (
    <Modal preventOverlayClick opened={openOrderAnalysis} small onClose={() => setOpenOrderAnalysis(false)}>
      <Grid fluid mt={30} mb={30}>
        <VSeparator />
        <H1>{Strings.orderAnalysis.title}</H1>
        <VSeparator />
        <FlexColumn>
          <Body mt={Spacing.Medium}>{Strings.orderAnalysis.firstRow}</Body>
          <Body mt={Spacing.Medium}>{Strings.orderAnalysis.secondRow}</Body>
          <Body mt={Spacing.Medium}>{Strings.orderAnalysis.thirdRow}</Body>
        </FlexColumn>
        <FlexColumn hAlign='flex-end' mt={30} mb={30}>
          <Button testid={`${TEST_ID_CLOSE_ORDER_ANALYSIS_MODAL}`} onClick={() => setOpenOrderAnalysis(false)}>
            {Strings.orderAnalysis.closeButton}
          </Button>
        </FlexColumn>
      </Grid>
    </Modal>
  );
};
