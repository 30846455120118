import React, { useContext, useRef } from 'react';
import { AnalysisResume, ClientDetail, ProductDimensionOrder } from '@app/models/analysis.model';
import { PopoverContext, PopoverContextState } from '@app/modules/components/popover/popover-container';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, FontSize, FontWeight, Spacing } from '@atomic/obj.constants';
import { ReferenceField } from '../../../../create-order-modal.style';

interface IsaReferenceFieldProps {
  item: AnalysisResume;
  clientDetail?: ClientDetail;
  productDimensionOrder: ProductDimensionOrder;
}

export const IsaReferenceField: React.FC<IsaReferenceFieldProps> = ({ item, productDimensionOrder }) => {
  const { openIsaReferenceItemPopover, closePopover } = useContext<PopoverContextState>(PopoverContext);

  const ref = useRef(null);

  return (
    <FlexColumn
      hAlign='center'
      vAlign='center'
      fullWidth
      onMouseOver={() => openIsaReferenceItemPopover(ref, item)}
      onMouseOut={() => closePopover()}
    >
      <FlexRow hAlign='center' vAlign='center'>
        <ReferenceField ref={ref}>
          <Label weight={FontWeight.Normal} fullWidth size={FontSize.Small} mr={Spacing.XSmall}>
            {productDimensionOrder.referenceIsa}
          </Label>
          <FaIcon.FlashWarning size='1x' color={Color.GrayXDark} />
        </ReferenceField>
      </FlexRow>
    </FlexColumn>
  );
};
