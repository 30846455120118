import React from 'react';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { ChatStrings } from '@app/modules/chat/chat.string';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Hbox } from '@atomic/obj.box';
import {
  DragAreaWrapper,
  ModalBoxStyled,
  ModalCloseStyled,
  ModalOpacityStyled,
  ModalStyled,
} from './modal.component.style';

export interface ModalProps {
  small?: boolean;
  medium?: boolean;
  opened?: boolean;
  dragable?: boolean;
  onClose?: () => void;
  preventOverlayClick?: boolean; // TODO: TEMPORARIO POR PEDIDO DO CLIENTE ATE RESOLVER COM CHAMADA NA API
  overflow?: string;
  customWidth?: string;
  maxWidth?: string;
  customHeight?: string;
  shadow?: boolean;
  testid?: string;
  overflowX?: string;
}

export const Modal: React.FC<ModalProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = ChatStrings[userInfo?.language]?.modalDragable;

  const handleOverlayClick = () => {
    if (!props.preventOverlayClick) {
      handleClose();
    }
  };

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  return (
    <ModalStyled data-testid={props.testid} opened={props.opened}>
      <ModalOpacityStyled opened={props.opened} onClick={handleOverlayClick} />
      {props.opened && (
        <Draggable positionOffset={{ x: '-50%', y: 0 }} handle='header'>
          <ModalBoxStyled
            overflow={props.overflow}
            small={props.small}
            medium={props.medium}
            maxWidth={props.maxWidth}
            customWidth={props.customWidth}
            customHeight={props.customHeight}
            shadow={props.shadow}
            overflowX={props.overflowX}
          >
            {props.dragable && (
              <DragAreaWrapper>
                <Hbox>{strings.label ? strings.label : strings.labelDefault}</Hbox>
              </DragAreaWrapper>
            )}
            {!!props.onClose && (
              <ModalCloseStyled>
                <FaIcon.Close onClick={handleClose} />
              </ModalCloseStyled>
            )}
            {props.children}
          </ModalBoxStyled>
        </Draggable>
      )}
    </ModalStyled>
  );
};
