import { format } from 'date-fns';
import React, { useRef, useState } from 'react';
import { ProductDimensionOrder } from '@app/models/analysis.model';
import { removeMaterialError } from '@app/modules/order-input/order-input-validation';
import {
  AMOUNT_TABLE_DATE_TOOLTIP_ARROW_LEFT_POSITION_REF,
  AMOUNT_TABLE_DATE_TOOLTIP_LEFT_POSITION_REF,
  AMOUNT_TABLE_DATE_TOOLTIP_TOP_POSITION_REF,
  AMOUNT_TABLE_DATE_TOOLTIP_WIDTH,
  MaterialCell,
} from '@app/modules/order-input/order-input.constants';
import { OrderInputStrings } from '@app/modules/order-input/order-input.string';
import { CustomDateField } from '@app/modules/order-input/phases/order-input-amount/partials/amount-table/custom-data-field/custom-date-field.component';
import { isM2DateRule } from '@app/modules/order-input/phases/order-input-amount/partials/amount-table/utils';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { FlexColumn } from '@atomic/obj.box';
import { FontSize } from '@atomic/obj.constants';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { hasProductError, hasProductInputError, isFirstM2ProductCaseSelector } from '../../../../utils/utils';
import { DateErrorWrapper } from './date-field.style';

interface IDateFieldProps {
  productDimensionOrder: ProductDimensionOrder;
  productDimensionOrders: ProductDimensionOrder[];
  setProductDimensionOrders: (dimensions: ProductDimensionOrder[]) => void;
}

const strings = OrderInputStrings.pt.dateAndAmount;

export const DateField: React.FC<IDateFieldProps> = ({
  productDimensionOrder,
  productDimensionOrders,
  setProductDimensionOrders,
}) => {
  const isFirstM2Date = isFirstM2ProductCaseSelector(productDimensionOrders, productDimensionOrder.tempId);
  const [show, setShow] = useState(true);
  const ref = useRef(null);

  const materialDate = productDimensionOrder.requestDate
    ? new Date(productDimensionOrder.requestDate.replaceAll('-', '/'))
    : null;
  const hasWarning = isM2DateRule(materialDate);

  let topTooltip = 0;
  let leftTooltip = 0;

  if (ref?.current) {
    const iconBoundings = ref?.current?.getBoundingClientRect();
    topTooltip = iconBoundings.top;
    leftTooltip = iconBoundings.left;
  }

  const customTop = topTooltip + AMOUNT_TABLE_DATE_TOOLTIP_TOP_POSITION_REF;
  const customLeft = leftTooltip - AMOUNT_TABLE_DATE_TOOLTIP_LEFT_POSITION_REF;

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current) {
      const isOutsideClick = ref && !ref.current.contains(event.target);

      if (isOutsideClick) {
        setShow(false);
      }
    }
  };

  const handleValueChange = (value: Date) => {
    const updatedProducts = productDimensionOrders.map(product => {
      if (product.tempId === productDimensionOrder.tempId) {
        product.requestDate = format(value, 'yyyy-MM-dd');
      }

      product.messages = removeMaterialError(product.messages, MaterialCell.Date);
      return product;
    });

    setProductDimensionOrders(updatedProducts);
  };

  useEventListener('click', handleClickOutside);

  return (
    <FlexColumn>
      <FlexColumn ref={ref} pt>
        <CustomDateField
          selectedDate={materialDate}
          onValueChange={(value: Date) => {
            handleValueChange(value);
          }}
          hasWarning={hasWarning}
          hasError={hasProductError(productDimensionOrder, MaterialCell.Date)}
        />
        <CustomizedTooltip
          description={strings.amountMessageTooltip}
          visible={hasWarning && show && isFirstM2Date}
          onClick={() => setShow(false)}
          onClose={() => setShow(false)}
          customHeight={customTop}
          customLeftAlign={customLeft}
          customArrowX={AMOUNT_TABLE_DATE_TOOLTIP_ARROW_LEFT_POSITION_REF}
          hasFooter={false}
          hasHeader={false}
          customWidth={AMOUNT_TABLE_DATE_TOOLTIP_WIDTH}
          fontSize={FontSize.XSmall}
          hasOffset
        />
      </FlexColumn>
      <DateErrorWrapper>{hasProductInputError(productDimensionOrder, MaterialCell.Date)}</DateErrorWrapper>
    </FlexColumn>
  );
};
