import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { RootState } from '@app/core/redux/store';
import { QuoteAttributesDataSource } from '@app/data/http';
import { EditAttributeRowsParams } from '@app/data/http/quote-attributes-params.dto';
import {
  mapRows,
  mapRowsDerogateUpdate,
  mapRowsDerogateUpdateMicroInclusion,
  mapSuggestion,
} from '@app/data/http/quote-attributes.dto';
import { Allowed } from '@app/data/http/quote.dto';
import { FullAnalysisData, QuoteAttributes } from '@app/models/quote.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { TechnicalAnalysusAttributeEvents } from '@app/modules/quote/quote.analytics';
import { TechnicalAnalysisContext } from '@app/modules/quote/technical-analysis/technical-analysis.context';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import { FlashMessageTargetName } from '@app/providers';
import { Cell } from '@atomic/atm.cell';
import { Frame } from '@atomic/atm.frame';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { useDeleteAttributes } from '../hooks/useDeleteAttributes';
import { useDeleteImageDerogate } from '../hooks/useDeleteImageDerogate';
import { AttributeCellHeader } from './attribute-cell-header.compoenent';
import { AttributeCellForm, AttributeCellFormData } from './attribute-cell.form';
import { Complementaries } from './complementaries.component';
import { DescriptiveAnalysis } from './descriptiveAnalysis.component';
import { DerogateRowValues, TechnicalAnalysisAttributeContext } from './technical-analysis-attribute.context';

interface AttributeCellProps {
  analysis: FullAnalysisData;
  title: string;
  status: string;
  onGerdauCommentsClick: () => void;
  onEditClick: () => void;
  plantKey: string;
  attribute: QuoteAttributes;
  readOnly: boolean;
  derogateOptionsList: Allowed[];
  recurrentProduct?: boolean;
  editedAttributes?: string[];
  derogateImages?: File[];
  provImages?: any[];
  derogateImagesLoading?: boolean;
  setProvImages?: any;
  setLoadingAttributes?: (value: boolean) => void;
  loadingDeleteImage?: boolean;
  tooltipNewDerogateImages: boolean;
  setTooltipNewDerogateImages: (value: boolean) => void;
  editRequest: (value: any) => void;
  editError: any;
  loadingEditRequest: boolean;
  getImg: any;
  setActionsLoading: (value: boolean) => void;
}

export const AttributeCell: React.FC<AttributeCellProps> = props => {
  const {
    isEditing,
    setIsEditing,
    editedStatus,
    setEditedStatus,
    onAttributeStatusChange,
    rowsStatus,
  } = React.useContext(TechnicalAnalysisAttributeContext);
  const { getPartialAnalysis, analysisId, setBlockLoading, blockLoading } = React.useContext(TechnicalAnalysisContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const [filesAttached, setFilesAttached] = React.useState([]);
  const [lightbox, setLightbox] = React.useState({ photoIndex: 0, isOpen: false });
  const strings = TechnicalAnalysisStrings[userInfo.language].attributes.attributeCell;

  const PLANT_INDEX = props.analysis.plants.findIndex(plant => plant.key === props.plantKey);
  const ATTRIBUTE_INDEX = props.analysis.plants[PLANT_INDEX].attributes.findIndex(
    attribute => attribute?.key === props.attribute?.key,
  );
  const analysisContext = props.analysis.plants[PLANT_INDEX].attributes[ATTRIBUTE_INDEX];

  const handleCancelClick = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.CancelAttributeEdition });
    setIsEditing(false);
    props.setProvImages([]);
    setFilesAttached([]);
    onAttributeStatusChange('');
    setEditedStatus(editedStatus);
  };

  const handleSuccessDeleteAttribute = () => {
    LogAnalytics.error({ tipo: TechnicalAnalysusAttributeEvents.DeleteAttributeSuccess });
    show('success', strings.successDeleteMessage(props.attribute.label));
  };

  const handleErrorDeleteAttribute = () => {
    LogAnalytics.error({ tipo: TechnicalAnalysusAttributeEvents.DeleteAttributeError });
    show('alert', strings.errorMessages.genericError);
  };

  const handleErrorDeleteImage = () => {
    show('alert', 'Ocorreu um erro ao apagar a imagem, recarregue a página e tente novamente.');
  };

  const handleSuccesDeleteImage = () => {
    setBlockLoading(false);
    setIsEditing(false);
    props.getImg({
      data: { analysisId, plantKey: props.plantKey, attributeKey: props.attribute.key, ATTRIBUTE_INDEX },
    });
  };

  const { mutate: deleteImage, error: deleteImageError, isLoading: deleteImageLoading } = useDeleteImageDerogate(
    handleSuccesDeleteImage,
    handleErrorDeleteImage,
  );

  const { mutate: deleteRequest, error: deleteError, isLoading: deleteLoading } = useDeleteAttributes(
    handleSuccessDeleteAttribute,
    handleErrorDeleteAttribute,
  );

  React.useEffect(() => {
    props.setActionsLoading(deleteImageLoading || deleteLoading);
  }, [deleteImageLoading, deleteLoading]);

  React.useEffect(() => {
    onAttributeStatusChange(props.status);
  }, [props.status, props.attribute]);

  const validateRange = (rowValues: DerogateRowValues[]) => {
    return !rowValues.some(row => row.minSuggestion > row.maxSuggestion);
  };

  const handleAcceptChangesClick = (data: AttributeCellFormData) => {
    const suggestion = mapSuggestion(props.attribute, data.suggestion, data.min, data.max);

    if (!validateRange(rowsStatus)) {
      LogAnalytics.error({ tipo: TechnicalAnalysusAttributeEvents.EditAttributeFormError });
      show('alert', strings.errorMessages.reasonRequired);
      !validateRange(rowsStatus) && show('alert', strings.errorMessages.invalidRange);
    } else {
      LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.EditAttribute });

      let rowsUpdated = [];
      if (rowsStatus.length > 0) {
        const rowsDerogate =
          editedStatus === 'accepted'
            ? rowsStatus
            : props.attribute.key === 'MICRO_INCLUSION'
            ? mapRowsDerogateUpdateMicroInclusion(rowsStatus)
            : mapRowsDerogateUpdate(rowsStatus);
        rowsUpdated = props.attribute.attributeValue.rows.map(row => row.splice(0, 4));
        rowsUpdated = rowsUpdated.map((tes, index) => {
          return tes.concat(rowsDerogate[index]);
        });
      }

      const payload = {
        analysisId,
        plantKey: props.plantKey,
        attributeKey: props.attribute?.key,
        attributeName: props.attribute?.label,
        status: editedStatus,
        reason: data.reason,
        rows: rowsStatus.length > 0 && (mapRows(rowsStatus) as [EditAttributeRowsParams[]]),
        rowsUpdated,
        suggestion,
        ATTRIBUTE_INDEX,
        images: props?.provImages,
      };

      setIsEditing(false);
      props.setLoadingAttributes(true);
      setBlockLoading(true);

      if (filesAttached.length > 0) {
        props.editRequest({ file: filesAttached, data: payload, acceptance: true, language: userInfo.language });
      } else {
        props.editRequest({
          data: payload,
          file: null,
          language: userInfo.language,
        });
      }
      setFilesAttached([]);
    }
  };

  const handleAcceptanceClick = (value: string) => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.AcceptDerogate });
    const payload = {
      analysisId,
      plantKey: props.plantKey,
      attributeKey: props.attribute?.key,
      attributeName: props.attribute?.label,
      language: userInfo.language,
      ATTRIBUTE_INDEX,
      suggestionStatus: value,
    };

    analysisContext.loading = true;

    props.editRequest({ data: payload, file: null, language: userInfo.language });
  };

  const handleDeleteSavedImage = indexFile => {
    setIsEditing(true);
    const idImage = typeof indexFile === 'string' ? indexFile : props.attribute.derogate.images[indexFile];

    if (idImage !== null) {
      deleteImage({
        analysisId,
        attributeKey: props.attribute.key,
        plantKey: props.plantKey,
        image: idImage,
        language: userInfo.language,
      });
    } else {
      show('alert', 'Ocorreu um erro ao apagar a imagem, recarregue a página e tente novamente.');
    }
  };

  const handleDeleteAttribute = () => {
    LogAnalytics.click({ tipo: TechnicalAnalysusAttributeEvents.DeleteAttribute });
    deleteRequest({
      data: { analysisId, attributeKey: props.attribute.key, plantKey: props.plantKey },
      language: userInfo.language,
    });
  };

  const handleComplementariesClick = () => {
    LogAnalytics.pageView(TechnicalAnalysusAttributeEvents.ComplementaryInfo);
  };

  const handleImages = files => {
    const handleReaderLoaded = readerEvt => {
      const binaryString = readerEvt.target.result;
      props.setProvImages(prevValue => [...prevValue, binaryString]);
    };

    const hasImages = props?.derogateImages ? props.derogateImages.length : props.attribute.derogate.images.length;

    if (files.length > 0) {
      if (files.length + hasImages > 3) {
        show('alert', 'Você só pode adicionar 3 imagens');
      } else {
        files.map(item => {
          setFilesAttached(prevValue => [...prevValue, item]);
          const reader = new FileReader();
          reader.onload = handleReaderLoaded.bind(this);
          reader.readAsDataURL(item);
        });
      }
    }
  };

  const handleDeleteProvImage = file => {
    const compare = index => {
      return index !== file;
    };
    const imagesProvLeft = props.provImages.filter((_, index) => compare(index));
    const filesAttachedLeft = filesAttached.filter((_, index) => compare(index));
    props.setProvImages(imagesProvLeft);
    setFilesAttached(filesAttachedLeft);
  };

  const handleErrorCritical = () => {
    LogAnalytics.error({ tipo: TechnicalAnalysusAttributeEvents.EditAttributeCriticalError });
    show('alert', `${strings.errorMessage}`);
    getPartialAnalysis({ id: analysisId });
  };

  const handleSuccessCritical = () => {
    show('success', strings.successMessage(props.attribute.label));
  };

  const { performRequest: editCritical } = useLazyRequest(
    QuoteAttributesDataSource.editCriticalAttribute,
    handleSuccessCritical,
    handleErrorCritical,
  );

  const handleChangeCritical = async (option: boolean) => {
    const payload = {
      analysisId: analysisId,
      plantKey: props.plantKey,
      attributeKey: props.attribute?.key,
      isCritical: option,
    };
    analysisContext.isCritical = option;

    editCritical({
      data: payload,
    });
  };

  const callLightBox = (imgs: any) => {
    return (
      <Lightbox
        mainSrc={imgs[lightbox.photoIndex]?.base64 ? imgs[lightbox.photoIndex].base64 : imgs[lightbox.photoIndex]}
        nextSrc={
          imgs[(lightbox.photoIndex + 1) % imgs.length]?.base64
            ? imgs[(lightbox.photoIndex + 1) % imgs.length].base64
            : imgs[(lightbox.photoIndex + 1) % imgs.length]
        }
        prevSrc={
          imgs[(lightbox.photoIndex + imgs.length - 1) % imgs.length]?.base64
            ? imgs[(lightbox.photoIndex + imgs.length - 1) % imgs.length].base64
            : imgs[(lightbox.photoIndex + imgs.length - 1) % imgs.length]
        }
        onCloseRequest={() => setLightbox({ ...lightbox, isOpen: false })}
        onMovePrevRequest={() =>
          setLightbox({ ...lightbox, photoIndex: (lightbox.photoIndex + imgs.length - 1) % imgs.length })
        }
        onMoveNextRequest={() => setLightbox({ ...lightbox, photoIndex: (lightbox.photoIndex + 1) % imgs.length })}
      />
    );
  };

  return (
    <LoadingState
      loading={deleteLoading || props.loadingDeleteImage || analysisContext?.loading}
      data={!!props.title}
      error={!!props.editError || !deleteError || !!deleteImageError}
    >
      <Frame>
        <Cell>
          <AttributeCellHeader
            title={props.title}
            status={props.status}
            isEditing={isEditing}
            recurrentProduct={props.recurrentProduct}
            readOnly={props.readOnly}
            attribute={props.attribute}
            editedStatus={editedStatus}
            onEditClick={() => setIsEditing(true)}
            onDeleteAttribute={handleDeleteAttribute}
            onAttributeStatusChange={onAttributeStatusChange}
            onChangeCritical={handleChangeCritical}
            editedAttributes={props.editedAttributes}
            flagInternationalStandard={props.analysis.flagInternationalStandard}
          />
          <VSeparator />
          {props.children}

          <VSeparator />
          {props.attribute?.descriptiveAnalysis && props.attribute?.descriptiveAnalysis?.length > 0 && (
            <DescriptiveAnalysis data={props.attribute?.descriptiveAnalysis} />
          )}
          <VSeparator />
          {props.attribute?.complementaries &&
            (props.attribute?.complementaries[0]?.tableValue?.columns?.length > 0 ||
              props.attribute?.complementaries[0]?.key === 'HARDNESS_CHART_AND_TABLE') && (
              <Complementaries
                handleComplementariesClick={handleComplementariesClick}
                complementaries={props.attribute?.complementaries}
                attributeTitle={props.title}
              />
            )}
          <VSeparator />
          {lightbox.isOpen && callLightBox(props.provImages.length > 0 ? props.provImages : props.derogateImages)}
          <AttributeCellForm
            analysis={props.analysis}
            title={props.title}
            isEditing={isEditing}
            editedStatus={editedStatus}
            onCancelClick={handleCancelClick}
            onSubmit={handleAcceptChangesClick}
            status={props.status}
            attribute={analysisContext}
            onAcceptanceClick={handleAcceptanceClick}
            images={props.derogateImages}
            derogateOptionsList={props.derogateOptionsList}
            provImages={props.provImages}
            derogateImagesLoading={
              props.loadingEditRequest ||
              props.derogateImagesLoading ||
              props.loadingDeleteImage ||
              deleteImageLoading ||
              blockLoading
            }
            handleImages={handleImages}
            handleDeleteImage={handleDeleteSavedImage}
            handleDeleteProvImage={handleDeleteProvImage}
            openZoom={value => setLightbox({ photoIndex: value, isOpen: true })}
            language={userInfo.language}
            tooltipNewDerogateImages={props.tooltipNewDerogateImages}
            setTooltipNewDerogateImages={props.setTooltipNewDerogateImages}
            setEditedStatus={setEditedStatus}
          />
        </Cell>
      </Frame>
    </LoadingState>
  );
};
