import React from 'react';
import { CreateOrderPhase } from '@app/models/analysis.model';
import { ProductTypeAndShape } from '@app/modules/quote/quote.contants';
import { OrderFormStrings } from '@app/modules/quote/quote.string';
import { TH, TR } from '@atomic/mol.table';

interface OrderTableHeaderProps {
  phase: string;
  language: string;
  productTypeAndShape: string;
  hasMultLength: boolean;
}

// eslint-disable-next-line complexity
export const OrderTableHeader: React.FC<OrderTableHeaderProps> = ({
  phase,
  productTypeAndShape,
  language,
  hasMultLength,
}) => {
  const Strings = OrderFormStrings[language].labels.tableHeader;

  switch (productTypeAndShape) {
    case ProductTypeAndShape.ArameRedondo || ProductTypeAndShape.FioMaquinaRedondo:
      return (
        <TR>
          <TH width={260}>{Strings.orderNumber}</TH>
          <TH width={155}>{Strings.orderItem}</TH>
          <TH>{Strings.isaNumber}</TH>
          <TH>{Strings.diameter}</TH>
          <TH width={135}>{Strings.amount}</TH>
          <TH width={120}>{Strings.unity}</TH>
          <TH width={170}>{Strings.date}</TH>
          {phase === CreateOrderPhase.Edit && <TH width={60}></TH>}
        </TR>
      );
    case ProductTypeAndShape.BarraRedonda:
      return (
        <TR>
          <TH width={260}>{Strings.orderNumber}</TH>
          <TH width={155}>{Strings.orderItem}</TH>
          <TH>{Strings.isaNumber}</TH>
          <TH>{Strings.diameter}</TH>
          <TH>{Strings.length}</TH>
          {hasMultLength && <TH>{Strings.multiLenght}</TH>}
          <TH width={135}>{Strings.amount}</TH>
          <TH width={120}>{Strings.unity}</TH>
          <TH width={170}>{Strings.date}</TH>
          {phase === CreateOrderPhase.Edit && <TH width={60}></TH>}
        </TR>
      );
    case ProductTypeAndShape.BarraQuadrada ||
      ProductTypeAndShape.BarraSextavada ||
      ProductTypeAndShape.BlocoQuadrado ||
      ProductTypeAndShape.TarugoQuadrado:
      return (
        <TR>
          <TH width={260}>{Strings.orderNumber}</TH>
          <TH width={155}>{Strings.orderItem}</TH>
          <TH>{Strings.isaNumber}</TH>
          <TH>{Strings.side}</TH>
          <TH>{Strings.length}</TH>
          {hasMultLength && <TH>{Strings.multiLenght}</TH>}
          <TH width={135}>{Strings.amount}</TH>
          <TH width={120}>{Strings.unity}</TH>
          <TH width={170}>{Strings.date}</TH>
          {phase === CreateOrderPhase.Edit && <TH width={60}></TH>}
        </TR>
      );
    case ProductTypeAndShape.BarraChata || ProductTypeAndShape.BarraRetangular || ProductTypeAndShape.BlocoRetangular:
      return (
        <TR>
          <TH width={260}>{Strings.orderNumber}</TH>
          <TH width={155}>{Strings.orderItem}</TH>
          <TH>{Strings.isaNumber}</TH>
          <TH>{Strings.width}</TH>
          <TH>{Strings.thickness}</TH>
          <TH>{Strings.length}</TH>
          {hasMultLength && <TH>{Strings.multiLenght}</TH>}
          <TH width={135}>{Strings.amount}</TH>
          <TH width={120}>{Strings.unity}</TH>
          <TH width={170}>{Strings.date}</TH>
          {phase === CreateOrderPhase.Edit && <TH width={60}></TH>}
        </TR>
      );
    case ProductTypeAndShape.FioMaquinaSextavado || ProductTypeAndShape.Lingote:
      return (
        <TR>
          <TH width={260}>{Strings.orderNumber}</TH>
          <TH width={155}>{Strings.orderItem}</TH>
          <TH>{Strings.isaNumber}</TH>
          <TH>{Strings.side}</TH>
          <TH width={135}>{Strings.amount}</TH>
          <TH width={120}>{Strings.unity}</TH>
          <TH width={170}>{Strings.date}</TH>
          {phase === CreateOrderPhase.Edit && <TH width={60}></TH>}
        </TR>
      );
    default:
      return (
        <TR>
          <TH width={260}>{Strings.orderNumber}</TH>
          <TH width={155}>{Strings.orderItem}</TH>
          <TH>{Strings.isaNumber}</TH>
          <TH>{Strings.diameter}</TH>
          <TH width={135}>{Strings.amount}</TH>
          <TH width={120}>{Strings.unity}</TH>
          <TH width={170}>{Strings.date}</TH>
          {phase === CreateOrderPhase.Edit && <TH width={60}></TH>}
        </TR>
      );
  }
};
