import * as React from 'react';
import { Frame } from '@atomic/atm.frame';
import { TextShimmerBoxStyled } from '@atomic/mol.shimmer/shimmer.component.style';
import { FlexColumn, FlexRow, Hbox } from '@atomic/obj.box';
import { Col, Grid, Row } from '@atomic/obj.grid';

export const CreateOrderModalShimmer: React.FC = () => {
  return (
    <Frame height='500px'>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <FlexRow pt={80}>
              <TextShimmerBoxStyled height='40px' width='400px' margin='0' />
            </FlexRow>
            <FlexColumn pt={40}>
              <FlexRow hAlign='space-between' fullWidth>
                <TextShimmerBoxStyled height='40px' width='200px' margin='0' />
                <TextShimmerBoxStyled height='40px' width='200px' margin='0' />
                <TextShimmerBoxStyled height='40px' width='200px' margin='0' />
                <TextShimmerBoxStyled height='40px' width='200px' margin='0' />
              </FlexRow>
              <Hbox.Separator />
              <FlexRow pt={40} hAlign='space-between' fullWidth>
                <TextShimmerBoxStyled height='40px' width='200px' margin='0' />
                <TextShimmerBoxStyled height='40px' width='200px' margin='0' />
                <TextShimmerBoxStyled height='40px' width='200px' margin='0' />
                <TextShimmerBoxStyled height='40px' width='200px' margin='0' />
              </FlexRow>
            </FlexColumn>
            <FlexRow pt={80}>
              <TextShimmerBoxStyled height='250px' />
            </FlexRow>
          </Col>
        </Row>
      </Grid>
    </Frame>
  );
};
