import styled, { css } from 'styled-components';
import { Border, Color, DesignSystemColor, Spacing } from '@atomic/obj.constants';

export const POPOVER_WIDTH = 240;
export const POPOVER_HEIGHT = 120;
export const POPOVER_Z_INDEX = 99;
export const ARROW_DIMENSIONS = 24;

export const IsaReferencePopoverWrapper = styled.div`
  position: absolute;
  z-index: ${POPOVER_Z_INDEX};
  width: ${POPOVER_WIDTH}px;
  height: ${POPOVER_HEIGHT}px;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  background-color: ${DesignSystemColor.DarkBlue};
  border-radius: ${Border.RadiusMedium};
`;

export const IsaReferencePopoverBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Spacing.Medium};
  background-color: ${Color.White};
  border-radius: ${Border.RadiusMedium};
  box-shadow: 0px 0px 40px rgba(114, 114, 114, 0.35);
  height: 320px;
  background-color: ${DesignSystemColor.DarkBlue};
`;

interface IsaReferencePopoverArrowProps {
  xAxis: number;
  alignRight?: boolean;
  alignBottom?: boolean;
}

const arrowUpCss = css`
  border-bottom-color: ${DesignSystemColor.DarkBlue};
  border-width: 0 ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px;
  top: -9px;
`;

const arrowDownCss = css`
  border-top-color: ${DesignSystemColor.DarkBlue};
  border-width: ${ARROW_DIMENSIONS}px ${ARROW_DIMENSIONS}px 0 ${ARROW_DIMENSIONS}px;
  top: 184px;
`;

export const IsaReferencePopoverArrowStyled = styled.div`
  height: ${ARROW_DIMENSIONS}px;
  width: ${ARROW_DIMENSIONS}px;
  border-color: transparent;
  border-style: solid;
  position: absolute;
  ${(props: IsaReferencePopoverArrowProps) => (props.alignBottom ? arrowDownCss : arrowUpCss)};
  top: 320px;
  left: ${(props: IsaReferencePopoverArrowProps) => (props.alignRight ? 'auto' : `${props.xAxis}px`)};
  right: ${(props: IsaReferencePopoverArrowProps) => (props.alignRight ? '20px' : 'auto')};
  box-shadow: 0px 40px 40px rgba(114, 114, 114, 0.35);
`;
