import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { TEST_ID_CLOSE_CREATE_ORDER_MODAL } from '../../quote.contants';
import { OrderFormStrings } from '../../quote.string';

interface ErrorPlaceholderProps {
  setOpenOrderAnalysis: (open: boolean) => void;
}

export const WarningMEPlaceholder: React.FC<ErrorPlaceholderProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const Strings = OrderFormStrings[userInfo.language];

  return (
    <Grid fluid>
      <Hbox mt={30}>
        <Hbox.Item hAlign='center'>
          <Image.BuildingFeature />
        </Hbox.Item>
      </Hbox>
      <Hbox>
        <Hbox.Item hAlign='center'>
          <H2>{Strings.warningME}</H2>
        </Hbox.Item>
      </Hbox>
      <VSeparator small />

      <Hbox mb={30}>
        <Hbox.Item hAlign='center'>
          <Button
            kind='secondary'
            testid={`${TEST_ID_CLOSE_CREATE_ORDER_MODAL}`}
            onClick={() => props.setOpenOrderAnalysis(false)}
          >
            {Strings.cancellButton}
          </Button>
        </Hbox.Item>
      </Hbox>
    </Grid>
  );
};
