import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';

export const DescriptiveAnalysisTableStyled = styled.div`
  width: 100%;
`;

export const DescriptiveAnalysisTextStyled = styled.span`
  text-align: left;
  margin-top: ${Spacing.XSmall};
  margin-bottom: ${Spacing.XSmall};
  & > span {
    margin-right: ${Spacing.XSmall};
    line-height: ${Spacing.Large};
  }
`;
