import React from 'react';
import { ProductDimensionOrder } from '@app/models/analysis.model';
import { OrderFormStrings } from '@app/modules/quote/quote.string';
import { Label } from '@atomic/atm.typography';
import { FlexColumn } from '@atomic/obj.box';
import { FontSize, FontWeight } from '@atomic/obj.constants';

interface MultiLengthProps {
  language: string;
  productDimensionOrder: ProductDimensionOrder;
  index: number;
}

export const MultiLengthField: React.FC<MultiLengthProps> = ({ productDimensionOrder, language, index }) => {
  const Strings = OrderFormStrings[language].labels.selector;

  return (
    <FlexColumn hAlign='center' fullWidth>
      <Label weight={FontWeight.Normal} fullWidth size={FontSize.Small}>
        {index === 0 && `${Strings.multiLength} ${productDimensionOrder.steelMultLength}`}
      </Label>
      <Label weight={FontWeight.Normal} fullWidth size={FontSize.Small}>
        {index === 0 && `${Strings.multiLengthMin} ${productDimensionOrder.steelMultLengthMin}`}
      </Label>
      <Label weight={FontWeight.Normal} fullWidth size={FontSize.Small}>
        {index === 0 && `${Strings.multiLengthMax} ${productDimensionOrder.steelMultLengthMax}`}
      </Label>
    </FlexColumn>
  );
};
