import React from 'react';
import { ProductDimensionOrder } from '@app/models/analysis.model';
import { FlexColumn } from '@atomic/obj.box';
import { Spacing } from '@atomic/obj.constants';
import { CustomInputWrapper, OrderItemOffSet } from '../order-number-field/order-number-field.style';

interface IOrderItemProps {
  productDimensionOrder: ProductDimensionOrder;
  productDimensionOrders: ProductDimensionOrder[];
  setProductDimensionOrders: (dimensions: ProductDimensionOrder[]) => void;
}

export const OrderItemField: React.FC<IOrderItemProps> = ({
  productDimensionOrder,
  productDimensionOrders,
  setProductDimensionOrders,
}) => {
  const handleValueChange = (value: string) => {
    const updatedProducts = productDimensionOrders.map(product => {
      if (product.tempId === productDimensionOrder.tempId) {
        product.referenceOrderItem = value;
      }

      return product;
    });

    setProductDimensionOrders(updatedProducts);
  };

  return (
    <FlexColumn>
      <FlexColumn pt={Spacing.Medium}>
        <CustomInputWrapper>
          <input
            defaultValue={productDimensionOrder.referenceOrderItem}
            onBlur={e => {
              handleValueChange(e.target.value);
            }}
          />
        </CustomInputWrapper>
      </FlexColumn>
      <OrderItemOffSet />
    </FlexColumn>
  );
};
