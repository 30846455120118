import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '../storage/auth.storage';
import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET } from './constants';
import {
  DeleteAttributeParams,
  DeleteImageDerogateParams,
  EditAttributeParams,
  GetDerogateListParams,
  GetImagesDerogateParams,
  PostAttributeParams,
  PostAttributeToAllPlantsParams,
} from './quote-attributes-params.dto';

export const endpoint = {
  ANALYSIS: '/analyses',
  ANALYSIS_LIST: '/list',
  ANALYSIS_PLANT: '/plants',
  ANALYSIS_ATTRIBUTE: '/attributes',
  ANALYSIS_ASSETS: '/assets',
  SIMILAR_MATERIAL: '/similar-materials',
  ANALYSIS_COMMENTS: '/comments',
  USERS: '/users',
  ANALYSIS_BRAND: '/updateProperty',
  ANALYSIS_STATUS: '/status',
  CLIENTS: '/clients',
  BAGG_NOTIFICATION: '/bagg-notification',
  QUOTE: '/cotacao',
  PDF: '/pdf',
  CONFIGURATIONS: '/configurations',
  DASHBOARD: '/exports/excel',
  DOCUMENTS: '/isa/documents',
  STANDARD_REVIEW: '/isa/forms/standard-review',
  STANDARD_REVIEW_NLU: '/nlu',
  STANDARD_REVIEW_NLU_CREATE: '/nlu/create',
  SKU: '/sku',
  LOGS: '/logs',
  FORM: '/quote',
  CREATE_ORDER: '/mi/implantation-request/order',
};

const user = Container.get(AuthStorageService).getUser();
const defaultHeaders = {
  client_id: CXP_KEY_CLIENT_ID,
  client_secret: CXP_KEY_CLIENT_SECRET,
  'Content-Type': 'application/json',
  Accept: '*/*',
  'Access-Control-Allow-Origin': 'egerdau.com.br',
};

export const QuoteAttributesDataSource = {
  editAttributes: (params: EditAttributeParams): Promise<void> => {
    if (params.data.rows) {
      params.data.rows.forEach(row => {
        row.forEach(line => {
          delete line.colSpan;
          delete line.rowSpan;
        });
      });
    }

    let data = null;
    if (params.data.suggestionStatus) {
      data = {
        derogate: {
          suggestion: { status: { value: params.data.suggestionStatus } },
        },
      };
    } else {
      if (params.data.rows) {
        data = {
          status: params.data.status,
          derogate: params.data.reason && {
            suggestion: { value: params.data.suggestion },
            reason: params.data.reason,
          },
          value: {
            rows: params.data.rows,
          },
        };
      } else {
        data = {
          status: params.data.status,
          derogate: params.data.reason && {
            reason: params.data.reason,
            suggestion: { value: params.data.suggestion },
          },
        };
      }
    }

    const form = new FormData();

    form.append('data', JSON.stringify(data));
    form.append('file', params.file);

    return Container.get(HttpRequestBuilder)
      .patch(
        `${endpoint.ANALYSIS}/${params.data.analysisId}${endpoint.ANALYSIS_PLANT}/${params.data.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.data.attributeKey}`,
      )
      .headers({ ...defaultHeaders, language: user.language, 'sso-access': user.ssoAccess })
      .data(form)
      .interceptors({
        afterSuccess: async () => params.data.attributeName,
        afterError: async () => params.data.attributeName,
      })
      .execute();
  },

  editTestMethod: (params: EditAttributeParams): Promise<void> => {
    let data;

    if (params.data.derogate.reason === undefined || params.data.derogate.reason === '') {
      data = params.data?.testMethod && {
        status: params.data.status,
        testMethod: params.data.testMethod,
      };
    } else {
      data = params.data?.testMethod && {
        status: params.data.status,
        derogate: {
          suggestion: { value: params.data.derogate.suggestion },
          reason:
            params.data.derogate.reason !== undefined && params.data.derogate.reason !== ''
              ? params.data.derogate.reason
              : 'Motivo não informado',
        },
        testMethod: params.data.testMethod,
      };
    }

    const form = new FormData();
    form.append('data', JSON.stringify(data));

    return Container.get(HttpRequestBuilder)
      .patch(
        `${endpoint.ANALYSIS}/${params.data.analysisId}${endpoint.ANALYSIS_PLANT}/${params.data.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.data.attributeKey}`,
      )
      .headers({ ...defaultHeaders, language: user.language, 'sso-access': user.ssoAccess })
      .data(form)
      .execute();
  },

  postAttribute: (params: PostAttributeParams): Promise<void> => {
    const data = {
      label: params.label,
      type: 'text',
      value: params.value,
    };

    return Container.get(HttpRequestBuilder)
      .post(
        `${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}`,
      )
      .headers({ ...defaultHeaders, language: user.language, 'sso-access': user.ssoAccess })
      .data(data)
      .execute();
  },

  postAttributeToAllPlants: (params: PostAttributeToAllPlantsParams): Promise<void> => {
    const data = {
      label: params.label,
      type: 'text',
      value: params.value,
    };

    return Container.get(HttpRequestBuilder)
      .post(`${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}${endpoint.ANALYSIS_ATTRIBUTE}`)
      .headers({ ...defaultHeaders, language: user.language, 'sso-access': user.ssoAccess })
      .data(data)
      .execute();
  },

  deleteAttribute: (params: DeleteAttributeParams): Promise<void> => {
    return Container.get(HttpRequestBuilder)
      .delete(
        `${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}`,
      )
      .headers({ ...defaultHeaders, language: user.language, 'sso-access': user.ssoAccess })
      .execute();
  },

  deleteImageDerogate: (params: DeleteImageDerogateParams): Promise<void> => {
    const image = params.image;
    return Container.get(HttpRequestBuilder)
      .delete(
        `${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}/${image}`,
      )
      .headers({ ...defaultHeaders, language: user.language, 'sso-access': user.ssoAccess })
      .interceptors({
        afterSuccess: async () => params.image,
      })
      .execute();
  },

  getImagesDerogate: (params: GetImagesDerogateParams): Promise<void> => {
    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_ASSETS}/${params.assetKey}`)
      .headers({ ...defaultHeaders, language: user.language, 'sso-access': user.ssoAccess })
      .execute();
  },

  getDerogateList: (params: GetDerogateListParams): Promise<string[]> => {
    return Container.get(HttpRequestBuilder)
      .get(
        `${endpoint.ANALYSIS}/${params.analysisId}${endpoint.ANALYSIS_PLANT}/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}/list`,
      )
      .headers({ ...defaultHeaders, language: user.language, 'sso-access': user.ssoAccess })
      .interceptors({
        afterSuccess: async (res: string[]): Promise<string[]> => res,
      })
      .execute();
  },

  editCriticalAttribute: (params: EditAttributeParams): Promise<void> => {
    const data = { isCritical: params.data.isCritical };

    return Container.get(HttpRequestBuilder)
      .put(
        `${endpoint.ANALYSIS}/${params.data.analysisId}${endpoint.ANALYSIS_PLANT}/${params.data.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.data.attributeKey}`,
      )
      .headers({ ...defaultHeaders, language: user.language, 'sso-access': user.ssoAccess })
      .data(data)
      .execute();
  },
};
