import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { CreateOrderParams } from '@app/models/analysis.model';

const user = Container.get(AuthStorageService).getUser();
const azureToken = window.localStorage.getItem('azureToken');

export const postOrder = async (params: CreateOrderParams) => {
  const defaultHeaders = {
    ...Container.get(HttpRequestBuilder).config.headers,
    language: params.language,
    'Access-Token': user?.accessToken,
    Authorization: `Bearer ${azureToken}`,
  };

  const body = params;
  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.CREATE_ORDER}`;

  await axios.post(url, body, { headers: defaultHeaders });
};

export const usePostOrder = (onSuccess, onError) => {
  return useMutation(postOrder, {
    onSuccess,
    onError,
  });
};
