import React, { createContext, useState, RefObject } from 'react';
import { AnalysisResume } from '@app/models/analysis.model';
import { Popover } from '@app/modules/components/popover/popover.component';
import { OrderStatusPopover } from '@app/modules/order/order-tabs/MI/tab-open/cell-renderer/order-status/order-status-popover/order-status-popover.component';
import { IsaReferencePopover } from '@app/modules/quote/components/create-order-modal/create-order-modal-body/order-table/partials/isa-reference-popover/isa-reference-popover.component';

export interface PopoverContainerProps {
  children: JSX.Element;
}

export interface PopoverContextState {
  openPopover: (
    ref: RefObject<HTMLElement>,
    component: JSX.Element,
    bgColor?: string,
    arrowLeftProp?: number,
    openRight?: boolean,
  ) => void;
  openOrderStatusPopover: (ref: RefObject<HTMLElement>, data: any) => void;
  openIsaReferenceItemPopover?: (ref: RefObject<HTMLElement>, item: AnalysisResume) => void;
  closePopover: () => void;
}

export const PopoverContext = createContext(null);

const PX_Y_TO_UP = -30;
const PX_X_TO_LEFT = 300;
const PX_X_TO_LEFT_OPEN_RIGHT = 0;
const OS_PX_Y_TO_UP = 196;
const IR_PX_Y_TO_UP = 350;
const IR_PX = 50;

export const PopoverContainer: React.FC<PopoverContainerProps> = ({ children }) => {
  const [openPopoverState, setOpenPopoverState] = useState(false);
  const [openOrderStatusPopoverState, setOpenOrderStatusPopoverState] = useState(false);
  const [openIsaReferencePopoverState, setOpenIsaReferencePopoverState] = useState(false);
  const [elementRect, setElementRect] = useState<DOMRect>(null);
  const [componentState, setComponentState] = useState<JSX.Element>(null);
  const [orderStatusData, setOrderStatusData] = useState<any>(null);
  const [isaReferenceItem, setIsaReferenceItem] = useState<AnalysisResume>(null);
  const [backgroundColor, setBackgroundColor] = useState<any>(null);
  const [arrowLeft, setArrowLeft] = useState<number>(0);
  const [openRightState, setOpenRightState] = useState<boolean>(false);

  const openPopover = (ref, component, bgColor, arrowLeftProp, openRight) => {
    setElementRect(ref?.current?.getBoundingClientRect());
    setOpenPopoverState(true);
    setComponentState(component);
    setArrowLeft(arrowLeftProp);
    setOpenRightState(openRight);

    if (bgColor) {
      setBackgroundColor(bgColor);
    }
  };

  const openOrderStatusPopover = (ref, data) => {
    setElementRect(ref?.current?.getBoundingClientRect());
    setOpenOrderStatusPopoverState(true);
    setOrderStatusData(data);
  };

  const openIsaReferenceItemPopover = (ref, data) => {
    setElementRect(ref?.current?.getBoundingClientRect());
    setOpenIsaReferencePopoverState(true);
    setIsaReferenceItem(data);
  };

  const closePopover = () => {
    setOpenPopoverState(false);
    setOpenOrderStatusPopoverState(false);
    setOpenIsaReferencePopoverState(false);
  };

  const popoverContextValue: PopoverContextState = {
    closePopover,
    openPopover,
    openOrderStatusPopover,
    openIsaReferenceItemPopover,
  };
  const yAxis = elementRect?.y - PX_Y_TO_UP;
  const xAxis = elementRect?.x - (openRightState ? PX_X_TO_LEFT_OPEN_RIGHT : PX_X_TO_LEFT);

  const yAxisOS = elementRect?.y - OS_PX_Y_TO_UP;
  const xAxisOS = elementRect?.x;

  const yAxisIR = elementRect?.y - IR_PX_Y_TO_UP;
  const xAxisIR = elementRect?.x - IR_PX;

  return (
    <PopoverContext.Provider value={popoverContextValue}>
      {openPopoverState && (
        <Popover
          component={componentState}
          onClose={closePopover}
          x={xAxis}
          y={yAxis}
          backgroundColor={backgroundColor}
          arrowLeft={arrowLeft}
        />
      )}
      {openOrderStatusPopoverState && (
        <OrderStatusPopover data={orderStatusData} x={xAxisOS} y={yAxisOS}></OrderStatusPopover>
      )}
      {openIsaReferencePopoverState && (
        <IsaReferencePopover item={isaReferenceItem} x={xAxisIR} y={yAxisIR}></IsaReferencePopover>
      )}
      {children}
    </PopoverContext.Provider>
  );
};
